import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import './MediaFilter.scss'

import { general } from '../../../../constants'

class MediaFilter extends Component {
  static defaultProps = {
    lists: []
  }

  static propTypes = {
    ctrlHandleFilterChange: PropTypes.func
  }

  state = {
    mediaTypes: [
      {
        label: this.props.t('all'),
        value: general.ALL,
        selected: true
      },
      {
        label: this.props.t('media:video'),
        value: general.VIDEO,
        selected: false
      },
      {
        label: this.props.t('media:image'),
        value: general.IMAGE,
        selected: false
      },
      {
        label: this.props.t('media:audio'),
        value: general.AUDIO,
        selected: false
      },
      {
        label: this.props.t('media:gif'),
        value: general.GIF,
        selected: false
      },
      {
        label: this.props.t('v4:pdf'),
        value: general.PDF,
        selected: false
      },
      {
        label: this.props.t('v4:txt'),
        value: general.TEXT,
        selected: false
      },
      {
        label: this.props.t('v2:ar_assets'),
        value: general.ARIMAGE,
        selected: false
      }
    ]
  }

  generateMediaType = mediaTypes => {
    const filterMediaType = _.filter(mediaTypes, item => item.selected)

    const mediaTypeParam = _.map(filterMediaType, 'value').toString()

    this.props.ctrlHandleFilterChange(mediaTypeParam)
  }

  // hangeFilterChange = (value, ind) => {
  //   const { mediaTypes } = this.state

  //   let newJSON = [...mediaTypes]

  //   console.log(newJSON, 'new data json')

  //   newJSON[ind].selected = value

  //   if (newJSON[ind].value !== general.ALL) {
  //     if (!newJSON.find(item => item.selected)) {
  //       newJSON[0].selected = true
  //     } else {
  //       newJSON[0].selected = false
  //     }
  //   } else if (newJSON[ind].value === general.ALL) {
  //     newJSON[0].selected = false
  //   }

  //   this.generateMediaType(newJSON)

  //   this.setState({
  //     mediaTypes: newJSON
  //   })
  // }

  hangeFilterChange = (value, ind) => {
    const { mediaTypes } = this.state

    let newJSON = [...mediaTypes]

    if (newJSON[ind].value === general.ALL && value) {
      newJSON = newJSON.map(item => ({
        ...item,
        selected: item.value === general.ALL
      }))
    } else {
      newJSON[ind].selected = value

      if (!newJSON.find(item => item.selected)) {
        newJSON[0].selected = true
      } else {
        newJSON[0].selected = false
      }
    }

    this.generateMediaType(newJSON)

    this.setState({
      mediaTypes: newJSON
    })
  }

  render() {
    const { mediaTypes } = this.state
    return (
      <Fragment>
        <div className="filter-media">
          {mediaTypes &&
            mediaTypes.map((item, ind) => (
              <label className="filtermedia-wrapper">
                <input
                  name="checkbox"
                  type="checkbox"
                  checked={item.selected}
                  onChange={e => this.hangeFilterChange(e.target.checked, ind)}
                />
                <span className="checkmark" data-label={item.label}>
                  {item.label}
                </span>
              </label>
            ))}
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('media')(MediaFilter)
