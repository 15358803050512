import React, { useMemo, useState } from 'react'

import { Icon, Input, Tooltip } from 'antd'

import './ViewChannelVideos.scss'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const ViewChannelVideos = ({ videos = [], onSelectVideo, selectedVideoId }) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredVideos = useMemo(() => {
    const sortedVideos = videos.sort((a, b) => {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    })

    if (!searchValue) return sortedVideos

    const filteredVideos = sortedVideos.filter(video => {
      const titleOrFilename = video.filename ? 'filename' : 'title'
      const lowerCaseTitleOrFilename = video[titleOrFilename].toLowerCase()
      const lowerCaseSearchValue = searchValue.toLowerCase()

      return lowerCaseTitleOrFilename.includes(lowerCaseSearchValue)
    })

    return filteredVideos
  }, [videos, searchValue])
  const { t } = useTranslation()
  return (
    <div className="view-channel-videos-container">
      <div className="view-channel-videos-search">
        <Search
          placeholder="Search"
          onChange={e => setSearchValue(e.target.value)}
          className="view-channel-videos-search-input"
        />
      </div>

      <div className="view-channel-videos">
        {filteredVideos.map((video, index) => {
          return (
            <Video
              key={index}
              video={video}
              onSelect={() => onSelectVideo(video.id)}
              isSelected={video.id === selectedVideoId}
            />
          )
        })}
      </div>
    </div>
  )
}

const Video = ({ video = {}, onSelect, isSelected }) => {
  const [duration, setDuration] = useState(0)

  const handleDuration = duration => {
    setDuration(duration)
  }

  const videoUrl = useMemo(() => {
    return video && video.type === 'youtube'
      ? `https://www.youtube.com/watch?v=${video && video.id}`
      : video && video.link + '#t=5'
  }, [video])
  const { t } = useTranslation()
  return (
    <div
      className={`view-channel-videos-item ${isSelected &&
        'selected-video-item'}`}
      onClick={onSelect}
    >
      <div className="view-channel-videos-item-cover-container">
        <ReactPlayer
          url={videoUrl}
          width="120px"
          height="70px"
          className="view-channel-videos-item-cover"
          onDuration={handleDuration}
        />

        {isSelected && (
          <div className="view-channel-videos-item-playIcon">
            {t('v3:video_now')} <br />
            {t('v3:video_playing')}
          </div>
        )}
      </div>
      <div className="view-channel-videos-item-info">
        <p className="view-channel-videos-item-filename">
          <Tooltip title={video.filename || video.title}>
            {video.filename || video.title}
          </Tooltip>
        </p>
        <div className="view-channel-videos-item-stats">
          <span>
            {' '}
            {t('v3:video_no')} {video.order + 1}{' '}
          </span>
          <span className="view-channel-videos-item-stats-duration">
            {' '}
            <Icon type="clock-circle" /> <Duration seconds={duration} />
          </span>
        </div>
      </div>
    </div>
  )
}

function Duration({ seconds }) {
  return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ('0' + string).slice(-2)
}
export default ViewChannelVideos
