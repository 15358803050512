import React, { useState, useCallback } from 'react'
import { Upload, Icon, message, Modal, Button } from 'antd'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import './UploadDocument.scss'
import StoryCreationForm from './StoryCreationForm'

const UploadDocument = ({ onValidationChange }) => {
  const [fileList, setFileList] = useState([])

  const [isStoryFormVisible, setIsStoryFormVisible] = useState(false)

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const newFileList = acceptedFiles.map(file => ({
          uid: file.name,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file)
        }))
        setIsStoryFormVisible(true)
        setFileList(newFileList)
        onValidationChange(true) // Mark as valid since a file is added
      }
    },
    [onValidationChange]
  )

  const handleRemove = file => {
    setFileList(prev => prev.filter(item => item.uid !== file.uid))
    if (fileList.length <= 1) onValidationChange(false) // Invalidate if no files remain
  }

  const handleCloseStoryForm = () => {
    setIsStoryFormVisible(false)
  }

  return (
    <div className="upload-document">
      <div className="upload-document__header">
        <h1>Upload Document</h1>
        <p>Please upload a PDF document with your video script.</p>
      </div>

      <Dropzone
        onDrop={onDrop}
        accept="application/pdf"
        maxFiles={1}
        onDropRejected={() => message.error('Only one PDF file is allowed.')}
        style={{ border: 'none', padding: '0' }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="upload-document__dropzone">
            <Icon type="file-text" className="upload-document__icon" />
            <p className="upload-document__text">
              Drag & drop your document here, or click to select a file (PDF
              only)
            </p>
          </div>
        )}
      </Dropzone>

      <Upload
        fileList={fileList}
        onRemove={handleRemove}
        showUploadList={{ showRemoveIcon: true }}
        className="upload-document__list"
      />

      {fileList.length === 0 && (
        <p className="upload-document__error">
          Please upload a PDF document to proceed.
        </p>
      )}

      {fileList.length > 0 && (
        <Button
          className="upload-document__button"
          type="primary"
          onClick={() => setIsStoryFormVisible(true)}
        >
          Generate Story
        </Button>
      )}

      {isStoryFormVisible && (
        <StoryCreationForm
          uploadedFile={fileList}
          onCancel={handleCloseStoryForm}
        />
      )}
    </div>
  )
}

UploadDocument.propTypes = {
  onValidationChange: PropTypes.func.isRequired
}

export default UploadDocument
