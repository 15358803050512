import React, { Component, Fragment } from 'react'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { Container, Draggable } from 'react-smooth-dnd'
import _ from 'lodash'
import { Button, Input, Spin, message, Alert, Icon } from 'antd'
import { ResultQuestion, QuizUnityView, SimulationUnityView } from './internal'

import {
  getQuizById,
  quizValidate,
  quizDeleteAttempts,
  getSimulationInformation
} from '../../../../../services/api/quizes'

import { Quiz } from './styled'
import { withTranslation } from 'react-i18next'

import { getSession } from '../../../../../services/api/user'

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }
  return result
}

const SortableItem = SortableElement(({ value }) => (
  <Quiz.SortableItem>{value}</Quiz.SortableItem>
))

const SortableList = SortableContainer(({ children, helperContainer }) => (
  <Quiz.SortableList>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        helperContainer: helperContainer
      })
    )}
  </Quiz.SortableList>
))

class QuizView extends Component {
  state = {
    current: 0,
    wait: false,
    result: [],
    elements: [],
    criteria: [],
    quizFinish: false,
    quiz: {
      questions: []
    },
    quizFetch: false,
    validate: {
      result: {},
      fetch: false
    },
    token: '',
    isFullScreen: false,
    simulationAttemptsExceed: false,
    isGettingSimulationInfo: false
  }

  componentDidMount() {
    getSession().then(session => {
      this.setState(
        {
          token: session.idToken.jwtToken
        },
        () => {
          this.getQuizById()
        }
      )
    })
  }

  getQuizById = () => {
    const { content, toggleQuizRestriction } = this.props

    console.log('test', this.props)

    // disable feature to go to next page before quiz data is loaded
    toggleQuizRestriction(true)

    if (content.content) {
      this.setState({ quizFetch: true })
      return getQuizById(content.content).then(res => {
        this.setState(
          {
            quiz: res.data,
            quizFetch: false,
            wait: false,
            current: 0
          },
          () => {
            const { quiz } = this.state
            const { is_restricted } = quiz
            const { toggleQuizRestriction } = this.props
            if (!is_restricted) {
              toggleQuizRestriction(false)
            }

            // call simulation attempts api
            if (!_.isEmpty(quiz.simulation) && quiz.simulation.links.length) {
              this.getSimulationInformation()
            }
          }
        )
      })
    }
  }

  toggleQuizModal = () => {
    const { isEnableModal, toggleQuizModal } = this.props.quizesActions
    toggleQuizModal(!isEnableModal)
  }

  getSimulationInformation = () => {
    const { id: simulationId } = this.state.quiz
    const { id: userId } = this.props.user.info
    const { courseId } = this.props.ids

    const fetchSimulationInfo = () => {
      const simulationPayload = {
        userId,
        simulationId,
        courseId
      }

      getSimulationInformation(simulationPayload).then(res => {
        const attemptsTaken = res.data.attemps
        this.setState({
          isGettingSimulationInfo: false,
          simulationAttemptsExceed: Number(attemptsTaken) > 2
        })
      })
    }

    this.setState(
      {
        isGettingSimulationInfo: true
      },
      fetchSimulationInfo
    )
  }

  onDropElements = e => {
    const { current, quiz, elements } = this.state
    const { payload } = e

    const step = quiz.questions[current]
    const prevElements = _.isEmpty(elements) ? step.elements : elements

    const removedIndex =
      prevElements && prevElements.findIndex(item => item === payload)

    this.setState({
      elements: this.shuffle(
        applyDrag(prevElements, { ...e, removedIndex: removedIndex })
      )
    })
  }

  onDropCriteria = (e, item, id) => {
    const { current, quiz } = this.state
    const questions = quiz.questions[current]

    if (e.payload.criterion) {
      return
    }

    this.setState(({ result: oldResult, criteria }) => {
      const elementsDrag = applyDrag(criteria, {
        ...e,
        payload: {
          id,
          uid: item.uid,
          criterion: item.criterion,
          element: e.payload
        }
      })

      const result = [...oldResult]

      result[current] = {
        uid: questions.uid,
        type: questions.type,
        answer: elementsDrag
      }

      return {
        criteria: elementsDrag,
        result
      }
    })
  }

  /**
   * Shuffles array in place.
   * @param {Array} a items An array containing the items.
   */
  shuffle = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  onSortStart = t => {
    // console.log('on move', properties, event)
    // event.preventDefault()
    this.props.onDragStart()
  }

  onSortEnd = ({ oldIndex, newIndex }, id) => {
    const { result: oldResult, quiz } = this.state
    const questions = quiz.questions[id]

    const result = [...oldResult]
    result[id] = {
      uid: questions.uid,
      type: questions.type,
      answer: arrayMove(
        result[id] ? result[id].answer : questions.answers,
        oldIndex,
        newIndex
      )
    }
    this.setState({ result })
    console.log('sort start is done')
    this.props.onDragStop()
  }

  onChangeQuestion = (value, id) => {
    const { result: oldResult, quiz } = this.state
    const questions = quiz.questions[id]
    const answers = questions.answers

    const arrayMultiple = []
    const totalProps = {
      uid: questions.uid,
      type: questions.type
    }

    const result = [...oldResult]

    if (questions.type === 'single') {
      result[id] = {
        ...totalProps,
        answer: [
          {
            uid: answers[value].uid,
            answer: answers[value].answer
          }
        ]
      }
    } else if (questions.type === 'multiple') {
      answers.forEach(item => {
        value.forEach(el => {
          if (answers.indexOf(item) === el) {
            arrayMultiple.push({
              uid: item.uid,
              answer: item.answer
            })
          }
        })
      })

      result[id] = {
        ...totalProps,
        answer: arrayMultiple
      }
    } else if (questions.type === 'free') {
      result[id] = {
        ...totalProps,
        answer: [{ answer: value }]
      }
    }

    this.setState({ result })
  }

  onNext = () => {
    const { current } = this.state

    this.setState({
      current: current + 1,
      elements: [],
      criteria: []
    })
  }

  onReset = () => {
    const { result: oldResult, current } = this.state

    const result = [...oldResult]

    result.splice(current, 1)

    this.setState({
      elements: [],
      criteria: [],
      result
    })
  }

  onValidateBefore = () => {
    const { quiz } = this.state
    const {
      user: { info },
      ids: { courseId, lessonId }
    } = this.props

    const param = {
      quiz_id: quiz.id,
      user_id: info.id,
      course_id: courseId,
      lesson_id: lessonId
    }
    this.setState({
      wait: true
    })

    quizDeleteAttempts(param).then(res => {
      this.onValidate()
    })
  }

  onRetry = () => {
    this.setState(
      {
        quizFinish: false,
        wait: true
      },
      () => {
        this.getQuizById()
      }
    )
  }

  onValidate = (isValidationFromUnity = false) => {
    const { result, quizFinish, quiz } = this.state
    const {
      ids: { lessonId, courseId, pageId },
      toggleQuizRestriction
    } = this.props

    this.setState({
      ...(!isValidationFromUnity && { quizFinish: !quizFinish }),
      validate: {
        result: {},
        fetch: true
      }
    })

    const data = {
      questions: result,
      course_id: courseId,
      lesson_id: lessonId,
      page_id: pageId
    }

    return quizValidate(data, quiz.id)
      .then(res => {
        const result = res.data.result
        console.log('result is', result)
        if (result && result.answer_incorrect === 0) {
          // enable user to go to next lesson
          toggleQuizRestriction(false)
        }
        this.setState({
          validate: {
            result: res.data,
            fetch: false
          },
          wait: false
        })
      })
      .catch(err => {
        message.info(err.message)
        this.setState(
          {
            quizFinish: false,
            validate: {
              result: {},
              fetch: false
            },
            wait: false
          },
          this.onReset
        )
      })
  }

  onClickFullscreen = () => {
    this.setState(
      prevState => ({
        isFullScreen: !prevState.isFullScreen
      }),
      () => {
        const elem = document.getElementById('quizUnity')
        const { isFullScreen } = this.state

        if (isFullScreen) {
          if (elem) {
            if (elem.requestFullscreen) {
              elem.requestFullscreen().catch(error => {
                console.error('Error entering fullscreen:', error)
              })
            } else if (elem.mozRequestFullScreen) {
              elem.mozRequestFullScreen()
            } else if (elem.webkitRequestFullscreen) {
              elem.webkitRequestFullscreen()
            } else if (elem.msRequestFullscreen) {
              elem.msRequestFullscreen()
            }
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen().catch(error => {
              console.error('Error exiting fullscreen:', error)
            })
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          }
        }
      }
    )
  }

  onCloseUnity = ({ questions }) => {
    this.setState(
      {
        result: questions
      },
      () => this.onValidate(true)
    )
  }

  getUnityLink = type => {
    const links = this.state.quiz.simulation.links
    const foundLink = links.find(link => link.filename.includes(type))
    return foundLink && foundLink.link
  }

  renderSimulation = () => {
    const { simulationAttemptsExceed, quiz, token, isFullScreen } = this.state
    const {
      user: {
        info: { id: userId }
      },
      ids
    } = this.props
    let unityUrl = {
      loaderUrl: this.getUnityLink('loader'),
      dataUrl: this.getUnityLink('data'),
      frameworkUrl: this.getUnityLink('framework'),
      codeUrl: this.getUnityLink('wasm')
    }

    if (simulationAttemptsExceed) {
      return (
        <Alert
          type="warning"
          message={`Simulation : ${quiz.simulation.title}`}
          description="Sorry ! You have exceeded all your attempts."
          showIcon
        />
      )
    }

    return (
      <Quiz.UnityContainer id="quizUnity">
        <SimulationUnityView
          unityUrl={unityUrl}
          simulationId={quiz.id}
          token={token}
          userId={userId}
          ids={ids}
        />
        <Quiz.FullScreen onClick={this.onClickFullscreen}>
          <Icon type={isFullScreen ? 'fullscreen-exit' : 'fullscreen'} />
        </Quiz.FullScreen>
      </Quiz.UnityContainer>
    )
  }

  // TODO: refactor this
  renderMatrixQuizElements = (elements, criteria, suffledElements, step) => {
    const { onDragStart, onDragStop, isFullscreen } = this.props

    if (isFullscreen) {
      return (
        <Container
          groupName="1"
          orientation="vertical"
          getChildPayload={i =>
            _.isEmpty(elements) && _.isEmpty(criteria)
              ? suffledElements[i]
              : elements[i]
          }
          onDragStart={onDragStart}
          onDragEnd={onDragStop}
          onDrop={e => {
            this.onDropElements(e)
            onDragStop()
          }}
          getGhostParent={() => document.getElementById('quiz-container')} // Pass the determined parent element here
          style={{ width: '100%' }}
        >
          {(_.isEmpty(elements) && _.isEmpty(criteria)
            ? step.elements
            : elements
          ).map((item, i) => (
            <Draggable key={i}>
              <Quiz.Element>{item}</Quiz.Element>
            </Draggable>
          ))}
        </Container>
      )
    }
    return (
      <Container
        groupName="1"
        orientation="vertical"
        getChildPayload={i =>
          _.isEmpty(elements) && _.isEmpty(criteria)
            ? suffledElements[i]
            : elements[i]
        }
        onDragStart={onDragStart}
        onDragEnd={onDragStop}
        onDrop={e => {
          this.onDropElements(e)
          onDragStop()
        }}
        getGhostParent={() => document.body}
        style={{ width: '100%' }}
      >
        {(_.isEmpty(elements) && _.isEmpty(criteria)
          ? step.elements
          : elements
        ).map((item, i) => (
          <Draggable key={i}>
            <Quiz.Element>{item}</Quiz.Element>
          </Draggable>
        ))}
      </Container>
    )
  }

  helperContainerFunction = () => {
    const { isFullscreen } = this.props
    return isFullscreen
      ? document.getElementById('quiz-container')
      : document.body
  }

  render() {
    const {
      current,
      result: stateResult,
      elements,
      criteria,
      quizFinish,
      quiz: { questions, feedback_incorrect },
      quizFetch,
      wait,
      quiz,
      validate: { result, fetch },
      token,
      isFullScreen,
      isGettingSimulationInfo
    } = this.state

    const {
      content,
      t,
      onDragStart,
      onDragStop,
      scale,
      isFullscreen: isCourseFullscreen
    } = this.props

    const step = questions && questions[current]
    const res = stateResult && stateResult[current]

    let suffledElements

    if (step && step.elements) {
      suffledElements = this.shuffle(step.elements)
    }

    const quizRetry =
      !quiz.is_restricted && quiz.attempts_count - quiz.user_attempts - 1 === 0
    const quizFinishDisable =
      !quiz.is_restricted && quiz.attempts_count - quiz.user_attempts === 0

    const unitySingleChoiceQuiz = quiz.is_quizgame && step.type === 'single'
    const unityMultipleChoiceQuiz = quiz.is_quizgame && step.type === 'multiple'
    const isQuizUnity = unitySingleChoiceQuiz || unityMultipleChoiceQuiz

    const showSimulation =
      !isGettingSimulationInfo &&
      !_.isEmpty(quiz.simulation) &&
      quiz.simulation.links.length

    if (showSimulation) {
      return this.renderSimulation()
    }

    const quizContainerHeight = isCourseFullscreen
      ? scale * content.height
      : content.height

    return (
      <Spin spinning={quizFetch || isGettingSimulationInfo}>
        <Quiz
          id="quiz-container"
          style={{
            height: quizContainerHeight
          }}
        >
          {!fetch ? (
            <Fragment>
              {!_.isEmpty(result) && quizFinish && (
                <Quiz.Result>
                  <Quiz.Result.Title status={result.feedback.status}>
                    {result.feedback.title}
                  </Quiz.Result.Title>

                  {result.answers.map((item, index) => (
                    <Fragment key={index}>
                      {false && item.type === 'single' && (
                        <ResultQuestion title={`${index + 1}. ${item.title}`}>
                          <Quiz.Result.Label status={item.answer.correct}>
                            <Quiz.Result.Icon
                              type={
                                item.answer.correct ? 'check' : 'close-circle'
                              }
                            />{' '}
                            {item.answer.answer}
                          </Quiz.Result.Label>
                        </ResultQuestion>
                      )}

                      {['multiple', 'single', 'free'].includes(item.type) && (
                        <ResultQuestion title={`${index + 1}. ${item.title}`}>
                          {item.answer.map((el, i) => (
                            <Quiz.Result.Label key={i} status={el.correct}>
                              <Quiz.Result.Icon
                                type={el.correct ? 'check' : 'close-circle'}
                              />{' '}
                              {el.answer}
                            </Quiz.Result.Label>
                          ))}
                        </ResultQuestion>
                      )}

                      {false && item.type === 'free' && (
                        <ResultQuestion title={`${index + 1}. ${item.title}`}>
                          <Quiz.Result.Label status={item.correct}>
                            <Quiz.Result.Icon
                              type={item.correct ? 'check' : 'close-circle'}
                            />{' '}
                            {item.answer}
                          </Quiz.Result.Label>
                        </ResultQuestion>
                      )}

                      {item.type === 'sorting' && (
                        <ResultQuestion title={`${index + 1}. ${item.title}`}>
                          {item.answer.map((el, i) => (
                            <Quiz.Result.Label key={i} status={el.correct}>
                              <Quiz.Result.Icon
                                type={el.correct ? 'check' : 'close-circle'}
                              />{' '}
                              {el.answer}
                            </Quiz.Result.Label>
                          ))}
                        </ResultQuestion>
                      )}

                      {item.type === 'matrix' && (
                        <ResultQuestion title={`${index + 1}. ${item.title}`}>
                          {item.answer.map((el, i) => (
                            <Quiz.Result.Label key={i} status={el.correct}>
                              <Quiz.Result.Icon
                                type={el.correct ? 'check' : 'close-circle'}
                              />{' '}
                              {el.criterion}: {el.element}
                            </Quiz.Result.Label>
                          ))}
                        </ResultQuestion>
                      )}
                    </Fragment>
                  ))}
                </Quiz.Result>
              )}

              {!_.isEmpty(result) &&
                quiz &&
                result.feedback.title === feedback_incorrect &&
                quizFinish && (
                  <Fragment>
                    {!quiz.is_restricted && (
                      <p style={{ fontSize: '1.5rem' }}>
                        <b>
                          You have{' '}
                          {quiz.attempts_count - quiz.user_attempts - 1} more
                          attempt(s) remaining
                        </b>
                      </p>
                    )}

                    {!quizRetry ? (
                      <Button
                        type="green"
                        onClick={() => {
                          this.onRetry()
                          this.onReset()
                        }}
                      >
                        {t('v4:retry')}
                      </Button>
                    ) : (
                      <Alert
                        message="You have exhausted all the attempts"
                        type="error"
                      />
                    )}
                  </Fragment>
                )}
            </Fragment>
          ) : (
            <Quiz.Result.Load>{t('quizview_loading')}</Quiz.Result.Load>
          )}

          {!_.isEmpty(questions) && !quizFinish && (
            <Fragment>
              {!isQuizUnity && (
                <Quiz.Title>
                  {t('labels:question')}{' '}
                  <Quiz.Strong>{current + 1}</Quiz.Strong> {t('general:of')}{' '}
                  <Quiz.Strong>{questions.length}</Quiz.Strong>
                </Quiz.Title>
              )}

              <Quiz.Content>
                {!isQuizUnity && <Quiz.Question>{step.title}</Quiz.Question>}

                {questions.map((qItem, qIndex) => (
                  <Fragment key={qIndex}>
                    {current === qIndex && (
                      <Fragment>
                        {step.type === 'single' && !unitySingleChoiceQuiz && (
                          <Quiz.ListRadioGroup
                            onChange={e =>
                              this.onChangeQuestion(e.target.value, current)
                            }
                          >
                            {step.answers.map((item, i) => (
                              <Quiz.Radio key={i} value={i}>
                                {item.answer}
                              </Quiz.Radio>
                            ))}
                          </Quiz.ListRadioGroup>
                        )}

                        {(unitySingleChoiceQuiz || unityMultipleChoiceQuiz) && (
                          <Quiz.UnityContainer id="quizUnity">
                            <QuizUnityView
                              gameId={quiz.cover.game_id}
                              quizId={quiz.id}
                              token={token}
                              onClose={this.onCloseUnity}
                            />
                            <Quiz.FullScreen onClick={this.onClickFullscreen}>
                              <Icon
                                type={
                                  isFullScreen
                                    ? 'fullscreen-exit'
                                    : 'fullscreen'
                                }
                              />
                            </Quiz.FullScreen>
                          </Quiz.UnityContainer>
                        )}

                        {step.type === 'multiple' && !unityMultipleChoiceQuiz && (
                          <Quiz.ListCheckboxGroup
                            onChange={v => this.onChangeQuestion(v, current)}
                          >
                            {step.answers.map((item, i) => (
                              <Quiz.Checkbox key={i} value={i}>
                                {item.answer}
                              </Quiz.Checkbox>
                            ))}
                          </Quiz.ListCheckboxGroup>
                        )}

                        {step.type === 'free' && (
                          <Input.TextArea
                            rows={4}
                            onChange={e =>
                              this.onChangeQuestion(e.target.value, current)
                            }
                          />
                        )}

                        {step.type === 'sorting' && (
                          //1.5.2
                          <SortableList
                            distance={1}
                            updateBeforeSortStart={this.onSortStart}
                            onSortEnd={v => this.onSortEnd(v, current)}
                            helperContainer={this.helperContainerFunction}
                          >
                            {(res ? res.answer : step.answers).map(
                              (item, i) => (
                                <SortableItem
                                  key={i}
                                  index={i}
                                  value={item.answer}
                                />
                              )
                            )}
                          </SortableList>
                        )}

                        {step.type === 'matrix' && (
                          <Fragment>
                            <Quiz.Reset
                              type="primary"
                              onClick={this.onReset}
                              size="large"
                            >
                              {t('buttons:reset_elements')}
                            </Quiz.Reset>

                            <Quiz.ElementsList
                              hide={criteria.length === step.elements.length}
                            >
                              {this.renderMatrixQuizElements(
                                elements,
                                criteria,
                                suffledElements,
                                step
                              )}
                            </Quiz.ElementsList>

                            <Quiz.CriterionList>
                              {step.answers.map((item, index) => (
                                <Fragment key={index}>
                                  <Quiz.CriterionContainer>
                                    <Quiz.Criterion>
                                      {item.criterion}
                                    </Quiz.Criterion>

                                    <Quiz.Area>
                                      <Container
                                        groupName="1"
                                        getChildPayload={c => criteria[c]}
                                        onDrop={e => {
                                          this.onDropCriteria(e, item, index)
                                          onDragStop()
                                        }}
                                        shouldAcceptDrop={(a, b) => {
                                          return true
                                        }}
                                      >
                                        {criteria.map((el, i) => (
                                          <Draggable key={i}>
                                            {el && (
                                              <Fragment>
                                                {el.id === index && (
                                                  <Quiz.ElementDrop>
                                                    {el.element}
                                                  </Quiz.ElementDrop>
                                                )}
                                              </Fragment>
                                            )}
                                          </Draggable>
                                        ))}
                                      </Container>
                                    </Quiz.Area>
                                  </Quiz.CriterionContainer>

                                  {item.content && (
                                    <Quiz.Image
                                      src={item.content}
                                      alt={item.criterion}
                                    />
                                  )}
                                </Fragment>
                              ))}
                            </Quiz.CriterionList>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </Quiz.Content>

              <Quiz.Action>
                {current < questions.length - 1 &&
                  !(unitySingleChoiceQuiz || unityMultipleChoiceQuiz) && (
                    <Button
                      type="primary"
                      onClick={() => this.onNext()}
                      disabled={res ? _.isEmpty(res.answer) : !res}
                    >
                      {t('buttons:next')}
                    </Button>
                  )}
                {!quizFinishDisable ? (
                  current === questions.length - 1 && (
                    <Button
                      type="green"
                      onClick={() =>
                        quiz &&
                        quiz.user_attempts > 0 &&
                        quiz.user_attempts < quiz.attempts_count
                          ? this.onValidateBefore()
                          : this.onValidate()
                      }
                      disabled={res ? _.isEmpty(res.answer) : !res}
                    >
                      {wait
                        ? t('v4:wait')
                        : quiz &&
                          quiz.user_attempts > 0 &&
                          quiz.user_attempts < quiz.attempts_count
                        ? wait
                          ? t('v4:wait')
                          : t('buttons:finish')
                        : t('buttons:finish')}
                    </Button>
                  )
                ) : (
                  <Alert
                    message="You have exhausted all the attempts"
                    type="error"
                  />
                )}
              </Quiz.Action>
            </Fragment>
          )}
        </Quiz>
      </Spin>
    )
  }
}

export default withTranslation('coursepage')(QuizView)
