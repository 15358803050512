import React, { useEffect, useState } from 'react'
import { Input, Dropdown, Menu } from 'antd'
import './CommentInput.scss' // Add custom styles for dropdown positioning

const characters = ['Alice', 'Bob', 'Charlie', 'David']

const CommentInput = ({ text, placeholder = 'Write a comment...' }) => {
  const [value, setValue] = useState('')
  const [mentionList, setMentionList] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    setValue(text)
  }, [text])

  const handleChange = e => {
    const text = e.target.value
    setValue(text)

    // Check for "@" and show suggestions
    const mentionTriggerIndex = text.lastIndexOf('@')
    if (mentionTriggerIndex !== -1) {
      const query = text.slice(mentionTriggerIndex + 1)
      const filteredList = characters.filter(char =>
        char.toLowerCase().startsWith(query.toLowerCase())
      )
      setMentionList(filteredList)
      setShowDropdown(filteredList.length > 0)
    } else {
      setShowDropdown(false)
    }
  }

  const handleSelect = mention => {
    const mentionTriggerIndex = value.lastIndexOf('@')
    const newValue =
      value.slice(0, mentionTriggerIndex) +
      `@${mention} ` +
      value.slice(mentionTriggerIndex + 1)
    setValue(newValue)
    setShowDropdown(false)
  }

  const menu = (
    <Menu>
      {mentionList.map(char => (
        <Menu.Item key={char} onClick={() => handleSelect(char)}>
          {char}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className="comment-input">
      <Dropdown overlay={menu} visible={showDropdown} trigger={[]}>
        <Input.TextArea
          value={value}
          onChange={handleChange}
          rows={4}
          placeholder={placeholder}
        />
      </Dropdown>
    </div>
  )
}

export default CommentInput
