import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Modal,
  Input,
  Icon,
  Tooltip,
  Card,
  Row,
  Switch,
  Col,
  Radio,
  Alert,
  Select
} from 'antd'

import options from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/storyFormOptions'
import { formatLabel } from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/utils'
import { api } from '../../../../services'
import { CreatableSelect } from '../../../../components'

const { Option } = Select

const StoryCreationForm = Form.create()(({ form, onCancel, text = '' }) => {
  const { getFieldDecorator } = form

  const [isGeneratingStory, setIsGeneratingStory] = useState(false)
  const [story, setStory] = useState(null)
  const [isStoryVisible, setIsStoryVisible] = useState(false)
  const [storyPosition, setStoryPosition] = useState('start')

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        const storyElements = JSON.stringify(values)

        setIsGeneratingStory(true)
        api.courses
          .generateStory({
            params: {
              course_content: text,
              story_elements: storyElements
            }
          })
          .then(res => {
            const storyObj = res.data.body[0]
            const storyString =
              storyObj.beginning + storyObj.middle + storyObj.end
            setStory(storyString)
            setIsStoryVisible(true)
          })
          .finally(() => setIsGeneratingStory(false))
      }
    })
  }

  function removeReferenceList(text) {
    var pattern = /References:(.*?)(?=<\/p>)/gs

    var textWithoutReferences = text.replace(pattern, '').trim()

    return textWithoutReferences
  }

  const onAddStory = () => {
    Modal.confirm({
      title: 'Do you want to add story to the pages?',
      content: 'The original content will be replaced with the story text.',
      onOk() {
        handleAddStory()
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleAddStory = () => {
    console.log('handleAddStory')
  }

  return (
    <Modal
      width={800}
      title={isStoryVisible ? 'Edit Story' : 'Story Preferences'}
      centered
      visible
      onCancel={() => onCancel()}
      footer={null}
      className={isStoryVisible ? 'edit-story' : 'story-preferences'}
    >
      {!isStoryVisible && (
        <Form className="story-course-form" onSubmit={handleSubmit}>
          {Object.entries(options).map(([key, values]) => (
            <Form.Item
              labelAlign="top"
              label={
                <span className="story-course-form-label">
                  <span>{formatLabel(key)} </span>
                  <Tooltip title={values.description}>
                    <Icon type="info-circle" />
                  </Tooltip>
                </span>
              }
              key={key}
            >
              {getFieldDecorator(key, {
                rules: [
                  {
                    required: true,
                    message: `Please input your ${formatLabel(key)}!`
                  }
                ]
              })(
                values.value && values.value.length > 0 ? (
                  <CreatableSelect
                    fieldKey={key}
                    updateOptionValue={(value, field) =>
                      form.setFieldsValue({ [field]: value })
                    }
                    options={values.value}
                    placeholder={`Select or type your ${formatLabel(key)}`}
                  />
                ) : (
                  <Input.TextArea
                    placeholder={`Enter your ${formatLabel(key)}`}
                  />
                )
              )}
            </Form.Item>
          ))}
          <div className="form-button-center">
            <Form.Item>
              <Button
                loading={isGeneratingStory}
                className="shl-primary-btn"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Generate Story
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}

      {isStoryVisible && (
        <StoryEditor
          data={story}
          storyPosition={storyPosition}
          onChange={setStory}
          onAddStory={onAddStory}
          setStoryPosition={setStoryPosition}
        />
      )}
    </Modal>
  )
})

const StoryEditor = ({
  data,
  saveOriginalContent,
  onChange,
  onAddStory,
  onCheckChange,
  storyPosition,
  setStoryPosition
}) => {
  const [story, setStory] = useState(null)

  useEffect(() => {
    if (data) {
      setStory(data)
    }
  }, [data])

  const handleChange = value => {
    setStory(value)
    onChange(value)
  }

  return (
    <div className="story-editor">
      <div className="story-editor__part">
        <Input.TextArea
          value={story}
          onChange={e => handleChange(e.target.value)}
          rows={10}
        ></Input.TextArea>
      </div>

      <Button className="shl-primary-btn" shape="round" onClick={onAddStory}>
        Use Story in Video
      </Button>
    </div>
  )
}

export default StoryCreationForm
