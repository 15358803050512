import React, { useState } from 'react'
import { Steps, Button, Icon } from 'antd'
import './styles.scss'
import NewHeader from '../../components/NewHeader'
import UploadDocument from './components/UploadDocument'
import Voiceover from './components/Voiceover'
import ShotList from './components/ShotList'
import ImageGeneration from './components/ImageGeneration'
import VideoGeneration from './components/VideoGeneration'
import CharacterSelection from './components/CharacterSelection'
import VideoStyle from './components/VideoStyle'

const { Step } = Steps

const VideoAi = () => {
  const [currentStep, setCurrentStep] = useState(0)
  // Track validation state for each step
  const [stepsValidation, setStepsValidation] = useState({
    0: true, // Upload Document
    1: true, // Voiceover
    2: true, // Shot List
    3: true, // Character
    4: true, // Image Generation
    5: true // Video Generation
  })

  const steps = [
    {
      title: 'Upload Document',
      icon: 'file-text'
    },
    {
      title: 'Voiceover',
      icon: 'sound'
    },
    {
      title: 'Character',
      icon: 'user'
    },
    {
      title: 'Video Style',
      icon: 'camera'
    },
    {
      title: 'Shot List',
      icon: 'ordered-list'
    },
    {
      title: 'Image Generation',
      icon: 'picture'
    },
    {
      title: 'Video Generation',
      icon: 'video-camera'
    }
  ]

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))
  }

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0))
  }

  // Function to update validation state for current step
  const updateStepValidation = isValid => {
    setStepsValidation(prev => ({
      ...prev,
      [currentStep]: isValid
    }))
  }

  // Function to get the previous and next step titles
  const getPreviousNextStepTitles = () => {
    const previousStepIndex = Math.max(currentStep - 1, 0)
    const nextStepIndex = Math.min(currentStep + 1, steps.length - 1)

    return {
      previousStepTitle: steps[previousStepIndex].title,
      nextStepTitle: steps[nextStepIndex].title
    }
  }

  // Function to render the current step's content
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return <UploadDocument onValidationChange={updateStepValidation} />
      case 1:
        return <Voiceover onValidationChange={updateStepValidation} />
      case 2:
        return <CharacterSelection onValidationChange={updateStepValidation} />
      case 3:
        return <VideoStyle onValidationChange={updateStepValidation} />
      case 4:
        return <ShotList onValidationChange={updateStepValidation} />
      case 5:
        return <ImageGeneration onValidationChange={updateStepValidation} />
      case 6:
        return <VideoGeneration onValidationChange={updateStepValidation} />
      default:
        return null
    }
  }

  return (
    <div className="video-generation">
      <div className="video-generation__header">
        <div className="video-generation__title">
          <h1>Create Video</h1>
          <p>Follow the steps below to generate your video</p>
        </div>
        <NewHeader />
      </div>

      <div className="video-generation__container">
        <Steps
          current={currentStep}
          labelPlacement="vertical"
          className="video-generation__steps"
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              icon={
                <div
                  className={`step-icon ${
                    currentStep === index
                      ? 'current'
                      : currentStep > index
                      ? 'completed'
                      : ''
                  }`}
                >
                  <Icon type={step.icon} />
                </div>
              }
            />
          ))}
        </Steps>

        <div className="video-generation__content">{renderStepContent()}</div>
        <div className="video-generation__navigation">
          {currentStep > 0 && (
            <Button
              size="large"
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className="nav-button"
            >
              <Icon type="left" />
              {getPreviousNextStepTitles().previousStepTitle}
            </Button>
          )}
          <Button
            type="primary"
            size="large"
            onClick={handleNext}
            disabled={
              currentStep === steps.length - 1 || !stepsValidation[currentStep]
            }
            className="nav-button"
          >
            Next: {getPreviousNextStepTitles().nextStepTitle}
            <Icon type="right" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VideoAi
