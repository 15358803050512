import React, { Component, createRef, Fragment } from 'react'
import {
  CustomTabs,
  PaginationRightAligned,
  ReportTable,
  ReportSelect,
  BackButton
  // SearchEventMargin,
  // CustomList,
  // CustomPopover,
  // CustomSelect,
} from '../../styled.js'
import { LeftOutlined } from '@ant-design/icons'
import { RenderArrows, Search, Spinner } from '../../../../components/index.js'
import { isSafari } from 'react-device-detect'
import {
  Tabs,
  Icon,
  Row,
  Col,
  Card,
  Spin,
  Result,
  Pagination,
  Divider,
  Modal,
  Select
} from 'antd'
import { generateCSV } from '../../../../utils.js'
import { userImage } from '../../images/index.js'
import { jsPDF } from 'jspdf'
import { withTranslation } from 'react-i18next'
import Tree from 'react-d3-tree'
import './styles.scss'
import { getNestedChildren } from '../../../Maze/Create/components/MazeScenario/mazeHelper.js'
import domtoimage from 'dom-to-image-more'
import ReportHeader from '../../components/ReportHeader.jsx'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants/index.js'
import moment from 'moment/moment'
import { api } from '../../../../services/index.js'

// const { Meta } = Card
// const { Text } = Typography
// const { Option } = CustomSelect
const { TabPane } = Tabs

const columns = t => [
  {
    title: t('reports:participant'),
    dataIndex: 'image',
    render: record => (
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <img
          src={record && record.link ? record.link : userImage}
          style={{
            objectFit: 'contain',
            height: '50px',
            width: '50px',
            borderRadius: '50%',
            border: '2px solid #c4c4c4'
          }}
        />
      </div>
    )
  },
  {
    textWrap: 'word-break',
    dataIndex: 'username'
  },
  {
    title: t('v2:total_play'),
    dataIndex: 'play_report',
    render: (text, record) => record.play_report && record.play_report.length
    // render(text, record) {
    //   return {
    //     children: <List
    //       dataSource={data}
    //       size='small'
    //       renderItem={item => (
    //         <List.Item style={{color:'black', borderBottom: '0'}}>
    //           {record.play_report && record.play_report.length > 1 ? item : 'No.1' }
    //         </List.Item>
    //       )}
    //     />
    //   }
    // }
  },
  {
    title: t('v4:detail')
  }
  // {
  //   title: 'Status',
  //   dataIndex: 'pass_status',
  //   render(text, record) {
  //     return {
  //       children: <List
  //         dataSource={record.play_report}
  //         size='small'
  //         renderItem={item => (
  //           <List.Item style={
  //               item.pass_status === true ?
  //               {color : '#339933', borderBottom: '0'} :
  //               {color : '#cc0000', borderBottom: '0' }
  //             }
  //           >
  //             {item.pass_status === true ? 'Passed' : 'Failed'}
  //           </List.Item>
  //         )}
  //       />
  //     }
  //   }
  // },
  // {
  //   title: 'Pass Score',
  //   dataIndex: 'minimum_pass_score',
  //   render(text, record) {
  //     return {
  //       children: <List
  //         dataSource={record.play_report}
  //         size='small'
  //         renderItem={item => (
  //           <List.Item style={{color:'black', borderBottom: '0'}}>
  //             {item.minimum_pass_score}
  //           </List.Item>
  //         )}
  //       />
  //     }
  //   }
  // },
  // {
  //   title: 'Time Spent (Mins)',
  //   dataIndex: 'time_spent',
  //   render(text, record) {
  //     return {
  //       children: <List
  //         dataSource={record.play_report}
  //         size='small'
  //         renderItem={item => (
  //           <List.Item style={{color:'black', borderBottom: '0'}}>
  //             {item.time_spent}
  //           </List.Item>
  //         )}
  //       />
  //     }
  //   }
  // },
]

let expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

class Preview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      translate: { x: 0, y: 0 },
      contents: [],
      activeKey: '1',
      visible: false,
      loading: true,
      page: report.PAGE_NUMBER,
      pageSize: report.PAGE_SIZE,
      participantsData: [],
      isGraphModalOpen: false,
      isMazeModalOpen: false,
      searchType: 'search',
      groups: []
    }
    this.treeContainer = createRef()
  }

  getDynamicPathClass = ({ source, target }) => {
    if (
      source.data.pathType === 'CHOSEN_IDEAL' &&
      target.data.pathType === 'CHOSEN_IDEAL'
    ) {
      return 'user_right_path'
    } else if (source.data.pathType && target.data.pathType) {
      return 'ideal_path_link'
    }
    return 'wrong_path_link'
  }

  renderRectSvgNode = params => {
    const { nodeDatum, toggleNode } = params
    return (
      <>
        <g>
          <circle
            r="40"
            pointerEvents="all"
            stroke="#E9E9E9"
            strokeWidth="3"
            fill={nodeDatum.type === 'Scenario' ? '#0967c0' : '#605890'}
            x="-10"
            id="myicon"
            onMouseOver={evt => this.showPopup(evt, nodeDatum)}
            onMouseOut={this.hidePopup}
          />
          <text strokeWidth="1" stroke="#E9E9E9" x="-5" y="5" fontSize="14">
            {nodeDatum.code}
          </text>
          <text strokeWidth="1" x="-20" y="70" fontSize="14">
            {nodeDatum.type}
          </text>
        </g>
      </>
    )
  }

  checkIdealStatus = data => {
    const { children } = data
    if (data.type === 'Option' && data.values.is_ideal_option) {
      return '#58905E'
    } else if (children.find(item => item.values.is_ideal_option === true)) {
      return '#58905E'
    }
    return '#605890'
  }

  showPopup = (evt, data) => {
    const mypopup = document.getElementById('mazeReportTooltip')
    mypopup.innerHTML = `<h3>${data.type}</h3><p>${(data.values &&
      data.values.content) ||
      'N/A'}</p>`
    mypopup.style.display = 'block'
    mypopup.style.left = evt.pageX - 50 + 'px'
    mypopup.style.top = evt.pageY - 250 + 'px'
    mypopup.style.zIndex = '1031'
  }

  hidePopup = evt => {
    const mypopup = document.getElementById('mazeReportTooltip')
    mypopup.style.display = 'none'
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props
    await this.props.getMazeById(id)

    const {
      reports: {
        maze: { data }
      }
    } = this.props

    this.setState({
      noMazeContent: data.contents ? data.contents.length === 0 : true,
      contents: data.contents,
      participantsData: data.participants,
      loading: false
    })

    this.getIdealPath()
    this.fetchGroups()
  }

  customExpandIcon = props => {
    return <Icon component={expandSvg} {...props} />
  }

  getIdealPath = () => {
    const data = this.state.contents
    const {
      reports: {
        maze: { fetch }
      }
    } = this.props

    if (fetch) {
      setTimeout(() => this.getIdealPath(), 500)
      return
    }

    if (!fetch && data.length === 0) {
      return
    }

    var nest = getNestedChildren(data)
    if (nest) this.generateIdealPath(data, nest[0])
  }

  generateIdealPath = (data, JSON) => {
    var idealPath = []
    function checkIdealCondition(arr) {
      if (arr===[] || arr === undefined) return
      arr.forEach(item => {
        if (item.values.is_ideal_option) {
          const scenario = item.children
          delete item.children
          idealPath.push(item)
          if (scenario.length === 0) return
          const scenarioChildren = scenario[0].children
          delete scenario[0].children
          idealPath.push(scenario[0])
          checkIdealCondition(scenarioChildren)
        }
      })
    }

    function getIdealPath() {
      if (JSON.code === 1) {
        const children = JSON.children
        delete JSON.children
        idealPath.push(JSON)
        checkIdealCondition(children)
      }
    }

    getIdealPath()

    var ids = new Set(idealPath.map(d => d.code))
    var merged = [...idealPath, ...data.filter(d => !ids.has(d.code))]
    const formattedData = merged.map(item => {
      const isIdealPath = idealPath.find(e => e.code===item.code)
      if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' }
      }
      return item
    })

    const treeData = getNestedChildren(
      formattedData.sort((a, b) => a.code - b.code)
    )

    this.setState({ treeData }, () => this.translateTreeToCenter())
  }

  setIdealPath = paths => {
    var ids = new Set(paths.chosen.map(d => d.code))
    var merged = [...paths.chosen, ...paths.ideal.filter(d => !ids.has(d.code))]
    const formattedData = merged.map(item => {
      const isIdealPath = paths.ideal.find(e => e.code===item.code)
      const chosenPath = paths.chosen.find(e => e.code===item.code)
      if (isIdealPath && chosenPath) {
        return { ...item, pathType: 'CHOSEN_IDEAL' }
      } else if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' }
      }
      return item
    })

    const treeData = getNestedChildren(formattedData)
    this.setState({ idealPathData: treeData[0] })
  }

  translateTreeToCenter = () => {
    const dimensions = this.treeContainer.getBoundingClientRect()
    this.setState({
      translate: {
        x: dimensions.width / 4,
        y: dimensions.height / 2
      }
    })
  }

  downloadParticipantReport = () => {
    const {
      reports: {
        maze: {
          data: { title, participants = [] }
        }
      }
    } = this.props

    let dataCSV = []
    participants &&
      participants.map((item, index) => {
        dataCSV.push({
          id: index + 1,
          username: item.username,
          pass_status: '',
          pass_score: '',
          time_spent: ''
        })
        item.play_report.map((x, index) => {
          dataCSV.push({
            id: '',
            username: '',
            pass_status: x.pass_status === true ? 'Passed' : 'Not Passed',
            pass_score: x.minimum_pass_score,
            time_spent: x.time_spent
          })
        })
      })

    const header = [
      'Id',
      'Username',
      'Pass Status',
      'Pass Score',
      'Time Spent (Mins)'
    ]
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', `maze-report-${title}.csv`)
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'maze-report.csv')
      ele.click()
    }
  }

  downloadMazeReport = () => {
    const { activeKey } = this.state

    if (activeKey === '1') {
      this.downloadParticipantReport()
    } else {
      this.downloadScenarioGraph()
    }
  }

  downloadScenarioGraph = () => {
    const domNode = document.getElementById('maze-report-graph')
    const scale = 1
    domtoimage
      .toJpeg(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      })
      .then(image => {
        var doc = new jsPDF({ orientation: 'landscape' })
        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()
        const widthRatio = width / domNode.clientWidth
        const heightRatio = height / domNode.clientHeight
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio

        const canvasWidth = domNode.clientWidth * ratio
        const canvasHeight = domNode.clientHeight * ratio

        const marginX = (width - canvasWidth) / 2
        const marginY = (height - canvasHeight) / 2

        doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight)
        const node = document.getElementById('maze-legends')
        const scaleLegend = 2
        domtoimage
          .toJpeg(node, {
            width: node.clientWidth * scaleLegend,
            height: node.clientHeight * scaleLegend,
            style: {
              transform: 'scale(' + scaleLegend + ')',
              transformOrigin: 'top left'
            }
          })
          .then(image => {
            doc.addPage()
            doc.addImage(image, 'JPEG', 0, 0)
            doc.text('Legend', 10, height / 3 + 10)
            const { contents } = this.state
            doc.text(
              `${contents[0].type} (${contents[0].code}) -> ${contents[0].values.content}`,
              15,
              height / 3 + 20
            )
            for (var i in contents) {
              if (i === 0) return
              doc.text(
                `${contents[i].type} (${contents[i].code}) -> ${contents[i].values.content}`,
                15,
                height / 3 + (20 + 10 * i)
              )
            }
            doc.save(`Maze.pdf`)
          })
      })
  }

  hide = () => {
    this.setState({
      visible: false
    })
  }

  handleVisibleChange = visible => {
    this.setState({ visible })
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    this.setState({
      page: page
    })
  }

  onSearch = val => {
    const {
      t,
      reports: {
        maze: {
          data: { participants }
        }
      }
    } = this.props
    if (val === '') {
      this.setState({ participantsData: participants })
      return
    }

    const searchResult = participants.filter(item => {
      return item.username.toLowerCase().includes(val.toLowerCase())
    })
    this.setState({ participantsData: searchResult })
  }

  renderMazePlayGraph = record => {
    const { t } = this.props
    return (
      // <div className="lesson-table">
      <table>
        <thead>
          <tr>
            <th>{t('v2:play')}</th>
            <th>{t('reports:status')}</th>
            <th>{t('reports:score')}</th>
            <th>{t('v2:time_spent_mins')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {record &&
            record.play_report.map((play, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td
                  style={
                    play.pass_status === true
                      ? {
                          color: '#339933',
                          borderBottom: '0',
                          textAlign: 'center'
                        }
                      : {
                          color: '#cc0000',
                          borderBottom: '0',
                          textAlign: 'center'
                        }
                  }
                >
                  {play.pass_status === true ? 'Passed' : 'Failed'}
                </td>
                <td>{play.score}</td>
                <td>{play.time_spent}</td>
                <td
                  className="view-graph"
                  style={{
                    paddingRight: '18px'
                  }}
                  onClick={() =>
                    this.setState({ isGraphModalOpen: true }, () =>
                      this.setIdealPath(play.paths)
                    )
                  }
                >
                  {t('v2:view_graph')}
                </td>
              </tr>
            ))}
          {/* <tr>
              <td></td>
              <td>Total</td>
              <td>
                {this.millisToMinutesAndSeconds(record.spending_time)} (minutes)
              </td>
              <td>{record.total_points}</td>
            </tr> */}
        </tbody>
      </table>
      // </div>
    )
  }

  onCancelGraphModal = () => {
    this.setState({
      isGraphModalOpen: false,
      idealPathData: []
    })
  }

  onCancelMazeModal = () => {
    this.setState({
      isMazeModalOpen: false
    })
  }

  handleSearchType = type => {
    const {
      reports: {
        maze: {
          data: { participants }
        }
      }
    } = this.props
    if (type === 'name') {
      this.setState({
        searchType: 'searchByname'
      })
    } else if (type === 'id') {
      this.setState({
        searchType: 'searchById'
      })
    } else if (type === 'email') {
      this.setState({
        searchType: 'searchByemail'
      })
    } else if (type === 'group_name') {
      this.setState({
        searchType: 'searchByGroup'
      })
    } else {
      this.setState(
        {
          searchType: 'search'
        },
        () => {
          this.setState({ participantsData: participants })
        }
      )
    }
  }

  onSearchWithType = val => {
    const { searchType, groups } = this.state
    const {
      reports: {
        maze: {
          data: { participants }
        }
      }
    } = this.props

    if (val === '') {
      this.setState({ participantsData: participants })
      return
    }

    if (searchType === 'search') {
      const searchResult = participants.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByname') {
      const searchResult = participants.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByGroup' && groups.length > 0) {
      const searchResult = participants.filter(item => {
        return (
          item.group && item.group.toLowerCase().includes(val.toLowerCase())
        )
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchById') {
      const searchResult = participants.filter(item => {
        return val.includes(item.user_id)
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByemail') {
      const searchResult = participants.filter(item => {
        return item.email.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    }
  }

  handleGroupSearch = value => {
    this.onSearchWithType(value)
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  callbackTabClicked = (key, event) => {
    if (key === '1') {
      this.props.history.push('/reports', 'user')
    }
  }

  render() {
    const {
      t,
      reports: {
        maze: {
          data: {
            title,
            participants,
            average_score,
            completed,
            no_of_participant,
            created_at,
            views,
            description
          },
          fetch
        }
      }
    } = this.props
    const {
      translate,
      noMazeContent,
      treeData,
      page,
      pageSize,
      loading,
      participantsData,
      isGraphModalOpen,
      isMazeModalOpen,
      idealPathData,
      searchType,
      groups
    } = this.state
    const tableData = fetch ? [] : participants
    const dataList =
      participantsData &&
      participantsData.slice(
        this.createOffset(page, pageSize),
        this.createOffset(page, pageSize) + 10
      )

    return (
      <Fragment>
        {fetch || loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <div className="content-warp">
            <div className="report-warp" style={{ direction: 'ltr' }}>
              <CustomTabs
                defaultActiveKey="0"
                onTabClick={this.callbackTabClicked}
                // tabBarGutter={15}
                tabBarExtraContent={
                  <ReportHeader
                    download={this.downloadMazeReport}
                    isDateActive
                  />
                  // <Button
                  //   className="download-report"
                  //   onClick={this.downloadMazeReport}
                  //   type="primary"
                  // >
                  //   {t('reports:report_download')}
                  // </Button>
                }
                // onTabClick={key => {
                //   this.setState({ activeKey: key })

                //   if (key === '0') {
                //     this.props.history.push(`/reports/maze/`)
                //   } else if (key === '2') {
                //     this.getIdealPath()
                //   }
                // }}
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <TypeSelect defaultValue="maze" />

                  <div className="custom-table">
                    <Row>
                      <Col span={8}>
                        <Row>
                          <BackButton
                            onClick={() =>
                              this.props.history.push('/reports/maze')
                            }
                            // type="link"
                            size="large"
                            // style={{  }}
                            // icon={}
                            style={{
                              // width: '92%',
                              marginTop: '20px',
                              marginLeft: '15px'
                            }}
                          >
                            <LeftOutlined />
                            {t('v4:back')}
                          </BackButton>
                        </Row>
                        <Card
                          className={
                            no_of_participant === 0
                              ? 'custom-card-empty'
                              : 'custom-card'
                          }
                        >
                          <Spin spinning={fetch} tip="Downloading graph...">
                            <div
                              // id="maze-report-graph"
                              className="table-img"
                              ref={tc => (this.treeContainer = tc)}
                              style={{
                                background: 'white'
                              }}
                            >
                              {treeData && (
                                <>
                                  <Tree
                                    data={treeData}
                                    pathClassFunc={this.getDynamicPathClass}
                                    renderCustomNodeElement={
                                      this.renderRectSvgNode
                                    }
                                    collapsible={false}
                                    translate={translate}
                                    // separation={{ siblings: 3, nonSiblings: 3 }}
                                  />
                                  <div id="mazeReportTooltip"></div>
                                </>
                              )}
                              {noMazeContent && (
                                <Result
                                  status="warning"
                                  title="Could not display graph"
                                  subTitle="Sorry, this maze has no scenarios."
                                />
                              )}
                            </div>
                            {/* <Popover
                                content={
                                  <div
                                    ref={tc => (this.treeContainer = tc)}
                                    style={{
                                      height: '37rem',
                                      width: '88rem',
                                    }}
                                  >
                                    {treeData && (
                                      <>
                                        <Tree
                                          data={treeData}
                                          pathClassFunc={this.getDynamicPathClass}
                                          renderCustomNodeElement={this.renderRectSvgNode}
                                          collapsible={false}
                                          translate={translate}
                                          // separation={{ siblings: 3, nonSiblings: 3 }}
                                        />
                                        </>
                                    )}
                                    {noMazeContent && (
                                      <Result
                                          status="warning"
                                          title="Could not display graph"
                                          subTitle="Sorry, this maze has no scenarios."
                                        />
                                    )}     
                                      <Icon 
                                        type="zoom-out"
                                        className="popover-icon"  
                                        onClick={this.hide}
                                      />
                                    </div>
                                    }
                                title={
                                  <div
                                    style={{
                                      marginLeft: '20px',
                                      marginTop: '7px'
                                    }}
                                  >
                                    <Row>
                                      <Col span={5}>
                                        <div className="legend-item">
                                          <div
                                            className="legend-item__shape"
                                            style={{ backgroundColor: 'green' }}
                                          ></div>
                                          Ideal Path
                                        </div>
                                      </Col>
                                      <Col span={19}>
                                        <div className="legend-item">
                                          <div
                                            className="legend-item__shape"
                                            style={{
                                              backgroundColor: '#0967c0',
                                              height: '12px',
                                              width: '12px',
                                              borderRadius: '50%'
                                             }}
                                          ></div>
                                          Scenario
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={5}>
                                        <div className="legend-item">
                                          <div
                                            className="legend-item__shape"
                                            style={{ backgroundColor: 'red' }}
                                          ></div>
                                            User Incorrect Path
                                          </div>               
                                      </Col>
                                      <Col span={19}>
                                        <div className="legend-item">
                                          <div
                                            className="legend-item__shape"
                                            style={{
                                              backgroundColor: '#605890',
                                              height: '12px',
                                              width: '12px',
                                              borderRadius: '50%'
                                            }}
                                          ></div>
                                          Option
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                }
                                trigger="click"
                                visible={this.state.visible}
                                onVisibleChange={this.handleVisibleChange}
                              >
                            </Popover>         */}
                            <Icon
                              type="zoom-in"
                              className="popover-icon"
                              onClick={() =>
                                this.setState({ isMazeModalOpen: true })
                              }
                            />
                            {isMazeModalOpen && (
                              <Modal
                                visible={isMazeModalOpen}
                                footer={false}
                                width="88%"
                                onCancel={this.onCancelMazeModal}
                              >
                                <Row>
                                  <Col span={18}>
                                    <div
                                      id="treeContainer"
                                      // ref={this.treeContainer}
                                      ref={tc => (this.treeContainer = tc)}
                                      style={{
                                        height: '76vh',
                                        background: 'white'
                                      }}
                                    >
                                      {treeData && (
                                        <>
                                          <Tree
                                            data={treeData}
                                            pathClassFunc={
                                              this.getDynamicPathClass
                                            }
                                            renderCustomNodeElement={
                                              this.renderRectSvgNode
                                            }
                                            collapsible={false}
                                            translate={translate}
                                            // separation={{ siblings: 3, nonSiblings: 3 }}
                                          />
                                        </>
                                      )}
                                      {noMazeContent && (
                                        <Result
                                          status="warning"
                                          title="Could not display graph"
                                          subTitle="Sorry, this maze has no scenarios."
                                        />
                                      )}
                                      <div id="mazeReportTooltip"></div>
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div
                                      className="legend-container"
                                      style={{ margin: 'auto 0' }}
                                    >
                                      <div className="legend-item">
                                        <div
                                          className="legend-item__shape thick__line"
                                          style={{ backgroundColor: 'teal' }}
                                        ></div>
                                        {t('v2:ideal_path_taken')}
                                      </div>
                                      <div className="legend-item">
                                        <div
                                          className="legend-item__shape"
                                          style={{ backgroundColor: 'green' }}
                                        ></div>
                                        {t('v2:ideal_path')}
                                      </div>
                                      <div className="legend-item">
                                        <div
                                          className="legend-item__shape"
                                          style={{ backgroundColor: 'red' }}
                                        ></div>
                                        {t('v2:user_incorrect_path')}
                                      </div>
                                      <div className="legend-item">
                                        <div
                                          className="legend-item__shape"
                                          style={{
                                            backgroundColor: '#0967c0',
                                            height: '20px',
                                            borderRadius: '50%'
                                          }}
                                        ></div>
                                        {t('v2:scenario')}
                                      </div>
                                      <div className="legend-item">
                                        <div
                                          className="legend-item__shape"
                                          style={{
                                            backgroundColor: '#605890',
                                            height: '20px',
                                            borderRadius: '50%'
                                          }}
                                        ></div>
                                        {t('v2:option')}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Modal>
                            )}
                          </Spin>
                          <p className="custom-description">{description}</p>
                          <Divider
                            type="horizontal"
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />

                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:created_date').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {moment(created_at).format('YYYY/MM/DD')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:participants').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {no_of_participant}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:avg_score')}
                            </Col>
                            <Col span={12} push={6}>
                              {average_score}%
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:views').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {views}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:completed').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {completed}
                            </Col>
                          </Row>
                          <Divider
                            type="horizontal"
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />

                          <Row>
                            <Col span={12}>
                              <div className="legend-item">
                                <div
                                  className="legend-item__shape"
                                  style={{ backgroundColor: 'green' }}
                                ></div>
                                {t('v2:ideal_path')}
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="legend-item">
                                <div
                                  className="legend-item__shape"
                                  style={{
                                    backgroundColor: '#0967c0',
                                    height: '12px',
                                    width: '12px',
                                    borderRadius: '50%'
                                  }}
                                ></div>
                                {t('v2:scenario')}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <div className="legend-item">
                                <div
                                  className="legend-item__shape"
                                  style={{ backgroundColor: 'red' }}
                                ></div>
                                {t('v2:user_incorrect_path')}
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="legend-item">
                                <div
                                  className="legend-item__shape"
                                  style={{
                                    backgroundColor: '#605890',
                                    height: '12px',
                                    width: '12px',
                                    borderRadius: '50%'
                                  }}
                                ></div>
                                {t('v2:option')}
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          columns={columns(t)}
                          dataSource={dataList}
                          loading={fetch}
                          pagination={false}
                          // expandIcon={null}
                          expandRowByClick
                          expandIconAsCell={false}
                          expandIconColumnIndex={3}
                          expandedRowRender={record =>
                            this.renderMazePlayGraph(record)
                          }
                          width
                          style={{
                            width: '92%',
                            // marginTop: "30px",
                            marginLeft: '35px'
                          }}
                          // className="table-row"
                          // borderSpacing
                          // expandedRowRender={record =>
                          //   this.renderLessonTable(record)
                          // }
                          // onExpand={this.onExpand}
                          expandIcon={this.customExpandIcon}
                          // expandedRowKeys={this.state.expandedRowKeys || []}
                          // expandIconAsCell
                          // expandRowByClick
                          // pagination={{
                          //   itemRender: RenderArrows,
                          //   pageSize: 10,
                          // }}

                          // style={{
                          //   width: "92%",
                          //   marginTop: "30px",
                          //   marginLeft: "35px",
                          // }}
                          title={() => (
                            <>
                              <Row
                                style={{ marginLeft: '30px', marginTop: '3px' }}
                              >
                                <Col span={13}>
                                  <div className="reports-user-search">
                                    <ReportSelect
                                      onChange={this.handleSearchType}
                                      className="rounded-select"
                                      dropdownStyle={{
                                        borderRadius: '9px'
                                      }}
                                      defaultValue={t('reports:all')}
                                      arrowColor
                                    >
                                      <Select.Option
                                        value={'search'}
                                        className="select-option-user"
                                      >
                                        {t('reports:all')}
                                      </Select.Option>
                                      <Select.Option
                                        value={'name'}
                                        className="select-option-user"
                                      >
                                        {t('reports:name')}
                                      </Select.Option>
                                      <Select.Option
                                        value={'group_name'}
                                        className="select-option-user"
                                      >
                                        {t('v4:group_name')}
                                      </Select.Option>
                                      <Select.Option
                                        value={'id'}
                                        className="select-option-user"
                                      >
                                        {t('reports:id_number')}
                                      </Select.Option>
                                      <Select.Option
                                        value={'email'}
                                        className="select-option-user"
                                      >
                                        {t('reports:email')}
                                      </Select.Option>
                                    </ReportSelect>

                                    {searchType === 'searchByGroup' &&
                                      groups.length > 0 && (
                                        <ReportSelect
                                          onChange={this.handleGroupSearch}
                                          className="rounded-select"
                                          dropdownStyle={{
                                            borderRadius: '9px'
                                          }}
                                          arrowColor
                                          defaultValue={t('v4:select_group')}
                                        >
                                          {groups.map(group => {
                                            return (
                                              <Select.Option
                                                key={group.id}
                                                value={group.title}
                                                className="select-option-user"
                                              >
                                                {group.title}
                                              </Select.Option>
                                            )
                                          })}
                                        </ReportSelect>
                                      )}

                                    {(searchType !== 'searchByGroup' ||
                                      (searchType === 'searchByGroup' &&
                                        groups.length===0)) && (
                                      <Search
                                        placeholder={t('v4:search_people')}
                                        onChange={e =>
                                          this.onSearchWithType(e.target.value)
                                        }
                                      />
                                    )}
                                  </div>
                                </Col>
                                {/* <Col span={8}>
                                <SearchEventMargin>
                                  <Search
                                    placeholder={t('v4:search_people')}
                                    onChange={e => this.onSearch(e.target.value)}
                                  />
                                </SearchEventMargin>
                              </Col>
                              <Col span={5}/> */}
                                <Col className="report-title" span={5} push={2}>
                                  {t('v4:total')} {no_of_participant}{' '}
                                  {t('reports:participants').toUpperCase()}
                                </Col>
                                <Col span={6} style={{ marginTop: '14px' }}>
                                  <PaginationRightAligned>
                                    <Pagination
                                      total={no_of_participant}
                                      current={page}
                                      pageSize={pageSize}
                                      onChange={this.onChangePagination}
                                      size="small"
                                      simple
                                    />
                                  </PaginationRightAligned>
                                </Col>
                              </Row>
                              <Divider style={{ margin: '0px' }} />
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  {isGraphModalOpen && (
                    <Modal
                      visible={isGraphModalOpen}
                      footer={false}
                      width="88%"
                      onCancel={this.onCancelGraphModal}
                    >
                      <Row>
                        <Col span={18}>
                          <div
                            id="treeContainer"
                            // ref={this.treeContainer}
                            ref={tc => (this.treeContainer = tc)}
                            style={{
                              height: '76vh',
                              background: 'white'
                            }}
                          >
                            <Tree
                              data={idealPathData}
                              pathClassFunc={this.getDynamicPathClass}
                              collapsible={false}
                              translate={translate}
                              renderCustomNodeElement={params =>
                                this.renderRectSvgNode(
                                  params,
                                  'mazeReportTooltip'
                                )
                              }
                              zoom={0.65}
                            />
                            <div id="mazeReportTooltip"></div>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div
                            className="legend-container"
                            style={{ margin: 'auto 0' }}
                          >
                            <div className="legend-item">
                              <div
                                className="legend-item__shape thick__line"
                                style={{ backgroundColor: 'teal' }}
                              ></div>
                              {t('v2:ideal_path_taken')}
                            </div>
                            <div className="legend-item">
                              <div
                                className="legend-item__shape"
                                style={{ backgroundColor: 'green' }}
                              ></div>
                              {t('v2:ideal_path')}
                            </div>
                            <div className="legend-item">
                              <div
                                className="legend-item__shape"
                                style={{ backgroundColor: 'red' }}
                              ></div>
                              {t('v2:user_incorrect_path')}
                            </div>
                            <div className="legend-item">
                              <div
                                className="legend-item__shape"
                                style={{
                                  backgroundColor: '#0967c0',
                                  height: '20px',
                                  borderRadius: '50%'
                                }}
                              ></div>
                              {t('v2:scenario')}
                            </div>
                            <div className="legend-item">
                              <div
                                className="legend-item__shape"
                                style={{
                                  backgroundColor: '#605890',
                                  height: '20px',
                                  borderRadius: '50%'
                                }}
                              ></div>
                              {t('v2:option')}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Modal>
                  )}
                  {/* <ReportTable
                  rowKey="id"
                  columns={columns(t)}
                  dataSource={tableData}
                  loading={fetch}
                  pagination={{
                    itemRender: RenderArrows,
                    pageSize: 10
                  }}
                /> */}
                </TabPane>
                <TabPane tab={t('reports:user_report')} key="1"></TabPane>
                <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>

                {/* <TabPane
                tab={t('v4:maze_view')}
                key="2"
                // style={{ direction: (lang === 'hebrew' || lang === 'arabic') ? 'rtl' : 'ltr' }}
              >
                <Row>
                  <Col span={24} className="section-title">
                    {t('v4:preview_mazeTitle')}
                    {title || <Spin />}
                  </Col> */}

                {/* <Col span={3}>
                    <CustomSelect
                      margin
                      defaultValue="5"
                      // onChange={value => this.handleSortByChange(value)}
                    >
                      <Option value="5">{t('reports:all')}</Option>
                      <Option value="1">{t('reports:name')}</Option>
                      <Option value="7">{t('v4:group_name')}</Option>
                      <Option value="30">{t('reports:id_number')}</Option>
                      <Option value="12">{t('reports:email')}</Option>
                    </CustomSelect>
                  </Col>
                  <Col span={2}>
                    <CustomSelect
                      margin
                      defaultValue="5"
                      // onChange={value => this.handleSortByChange(value)}
                    >
                      <Option value="5">Select Group</Option>
                    </CustomSelect>
                  </Col> */}
                {/* </Row>

                <Row>
                  <Col span={18}>
                    <Spin spinning={fetch} tip="Downloading graph...">
                      <div
                        id="maze-report-graph"
                        ref={tc => (this.treeContainer = tc)}
                        style={{
                          height: 'calc(100vh - 25rem)',
                          background: 'white',
                          display: 'grid',
                          placeItems: 'center'
                        }}
                      >
                        {treeData && (
                          <>
                            <Tree
                              data={treeData}
                              pathClassFunc={this.getDynamicPathClass}
                              renderCustomNodeElement={this.renderRectSvgNode}
                              collapsible={false}
                              translate={translate}
                              // separation={{ siblings: 3, nonSiblings: 3 }}
                            />
                            <div id="mazeReportTooltip"></div>
                          </>
                        )}
                        {noMazeContent && (
                          <Result
                            status="warning"
                            title="Could not display graph"
                            subTitle="Sorry, this maze has no scenarios."
                          />
                        )}
                      </div>
                    </Spin>
                  </Col>
                  <Col span={6}>
                    <div
                      className="legend-container legend-padding"
                      id="maze-legends"
                    >
                      <div className="legend-item">
                        <div
                          className="legend-item__shape"
                          style={{ backgroundColor: 'green' }}
                        ></div>
                        Ideal Path
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-item__shape"
                          style={{ backgroundColor: 'red' }}
                        ></div>
                        User Incorrect Path
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-item__shape"
                          style={{
                            backgroundColor: '#0967c0',
                            height: '20px',
                            borderRadius: '50%'
                          }}
                        ></div>
                        Scenario
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-item__shape"
                          style={{
                            backgroundColor: '#605890',
                            height: '20px',
                            borderRadius: '50%'
                          }}
                        ></div>
                        Option
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane> */}
              </CustomTabs>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
export default withTranslation('reports')(Preview)
