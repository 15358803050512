import React from 'react'
import { Modal, Slider, Switch, Button } from 'antd'

import './Voiceover.scss'
const VoiceSettings = ({ visible, onClose, settings, onSettingsChange }) => {
  return (
    <Modal
      title="Settings"
      centered
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="reset" onClick={() => onSettingsChange('reset')}>
          Reset
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={() => onSettingsChange('apply')}
          className="generate-btn"
        >
          Apply Settings
        </Button>
      ]}
      width={400}
      className="voice-settings-modal"
    >
      <div className="settings-content">
        <div className="slider-section">
          <div className="slider-header">
            <span>Stability</span>
            <span className="slider-label">More stable</span>
          </div>
          <Slider
            value={settings.stability}
            onChange={value => onSettingsChange('stability', value)}
            min={0}
            max={1}
            step={0.01}
            tipFormatter={null}
          />
          <div className="slider-label-left">More variable</div>
        </div>

        <div className="slider-section">
          <div className="slider-header">
            <span>Similarity</span>
            <span className="slider-label">High</span>
          </div>
          <Slider
            value={settings.similarity_boost}
            onChange={value => onSettingsChange('similarity_boost', value)}
            min={0}
            max={1}
            step={0.01}
            tipFormatter={null}
          />
          <div className="slider-label-left">Low</div>
        </div>

        <div className="slider-section">
          <div className="slider-header">
            <span>Style Exaggeration</span>
            <span className="slider-label">Exaggerated</span>
          </div>
          <Slider
            value={settings.style}
            onChange={value => onSettingsChange('style', value)}
            min={0}
            max={1}
            step={0.01}
            tipFormatter={null}
          />
          <div className="slider-label-left">None</div>
        </div>

        <div className="switch-section">
          <span>Speaker boost</span>
          <Switch
            checked={settings.use_speaker_boost}
            onChange={checked => onSettingsChange('use_speaker_boost', checked)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default VoiceSettings
