import React, { useState } from 'react'
import { Input } from 'antd'

import './ShotList.scss'

const ShotList = () => {
  const [scenes, setScenes] = useState([
    {
      id: 1,
      name: 'Morning Preparation',
      shots: [
        'Establishing shot: Office building exterior with people walking in and out',
        'Close-up: Alarm clock ringing on a bedside table',
        'Medium shot: Person gets out of bed, looking determined'
      ]
    },
    {
      id: 2,
      name: 'Work Preparation',
      shots: [
        'Close-up: Hands preparing coffee in the kitchen',
        'Over-the-shoulder shot: Person reviewing notes on a laptop',
        'Wide shot: Person practicing the presentation in front of a mirror'
      ]
    }
  ])

  const updateSceneName = (sceneId, newName) => {
    setScenes(
      scenes.map(scene =>
        scene.id === sceneId ? { ...scene, name: newName } : scene
      )
    )
  }

  const updateShot = (sceneId, shotIndex, newDescription) => {
    setScenes(
      scenes.map(scene =>
        scene.id === sceneId
          ? {
              ...scene,
              shots: scene.shots.map((shot, index) =>
                index === shotIndex ? newDescription : shot
              )
            }
          : scene
      )
    )
  }

  return (
    <div className="shot-list-shots">
      <div className="shot-list-header">
        <h1>Shot List</h1>
        <p>Describe each shot in detail.</p>
      </div>

      {scenes.map((scene, index) => (
        <div key={scene.id} className="scene-container">
          <h2 className="scene-name">Scene {index + 1}</h2>
          <Input
            className="scene-name-input"
            value={scene.name}
            onChange={e => updateSceneName(scene.id, e.target.value)}
          />

          {scene.shots.map((description, shotIndex) => (
            <div key={shotIndex} className="shot-list-shot">
              <Input
                addonBefore={`Shot ${shotIndex + 1}`}
                value={description}
                onChange={e => updateShot(scene.id, shotIndex, e.target.value)}
                className="shot-input"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ShotList
