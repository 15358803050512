import { Col, Row } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import history from '../../../../history'

function ActivityCounter({
  route,
  searchParams,
  totalCount,
  type,
  participants,
  timeSpent,
  icon,
  t
}) {
  return (
    <Col xl={4} md={8} sm={12}>
      <div
        className="report-count-container bluish-background"
        onClick={() => history.push(route, searchParams)}
      >
        <div className="report-count-info">
          <Row
            type="flex"
            justify="space-between"
            gutter={[8, 0]}
            align="middle"
          >
            <Col span={16}>
              <div className="count">{totalCount}</div>
            </Col>
            <Col span={8}>
              <img src={icon} />
            </Col>
            <Col span={24}>
              <h3 className="title">{type}</h3>
            </Col>
            <Col span={24}>
              <div className="activity">
                <h3>
                  {participants} {t('reports:participants')}
                </h3>
                <h3>{timeSpent}</h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  )
}

export default withTranslation()(ActivityCounter)
