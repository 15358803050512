import React, { Component, Fragment } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import {
  CustomTabs,
  ReportTable,
  PaginationRightAligned,
  BackButton,
  ReportSelect
} from '../../styled.js'
import { isSafari } from 'react-device-detect'
import { userImage } from '../../images'
import ReportHeader from '../../components/ReportHeader.jsx'
import { Search, Spinner } from '../../../../components'
import { withTranslation } from 'react-i18next'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants'
import { Tabs, Icon, Row, Card, Select, Col, Pagination, Divider } from 'antd'
import { generateCSV } from '../../../../utils'
import moment from 'moment/moment'
import '../../Report/style.css'
import './styles.scss'
import { api } from '../../../../services'

const { TabPane } = Tabs
// const { Panel } = Collapse

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const columns = t => [
  {
    title: t('reports:participant'),
    dataIndex: 'avatar',
    render: avatar => {
      return (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <img
            style={{
              objectFit: 'contain',
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              border: '2px solid #c4c4c4'
            }}
            src={avatar && avatar.link ? avatar.link : userImage}
            alt="userimage"
          />
        </div>
      )
    }
  },
  {
    title: 'Username',
    dataIndex: 'username',
    textWrap: 'word-break',
    width: 200
  },
  { title: t('v4:group'), dataIndex: 'group' },
  {
    title: t('reports:status'),
    dataIndex: 'status',
    render: status => {
      let color = status === 'fail' ? '#BC0000' : '#20AA26'

      return <p style={{ color: color }}>{capitalizeFirstLetter(status)}</p>
    }
  },

  { title: t('v4:pass_score'), dataIndex: 'scores' },
  {
    title: t('v4:time_spent'),
    dataIndex: 'spending_time',
    render: time => {
      const t = moment.duration(time).asMinutes()
      return `${t.toFixed(2)} minutes`
    }
  },
  { title: t('v4:detail') }
]

let expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

class Preview extends Component {
  state = {
    searchType: 'search',
    groups: [],
    loading: true,
    expandedRowKeys: 0,
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    participantsData: [],
    visible: false,
    expendIdNo: 0
  }

  getTimeDuration = time => {
    const t = moment.duration(time).asMinutes()
    return `${t.toFixed(2)} minutes`
  }

  renderSkillJourneyTable = data => {
    const { t } = this.props
    console.log('rendertabledata', data)
    const totalScore = data.resources.reduce(
      (total, item) => total + item.scores,
      0
    )
    const totalSpendingTime = data.resources.reduce(
      (total, item) => total + item.spend_time,
      0
    )
    console.log('rendertabledata', totalSpendingTime)
    return (
      <table className="lessons-table">
        <thead>
          <tr>
            <th>{t('labels:title')}</th>
            <th>{t('general:type')}</th>
            <th>{t('reports:score')}</th>
            <th>{t('v2:spend')}</th>
          </tr>
        </thead>
        <tbody>
          {data.resources.map((item, index) => (
            <tr key={index} style={{ fontWeight: 'normal' }}>
              <td>{item.title}</td>
              <td style={{ textAlign: 'center' }}>{item.type}</td>
              <td style={{ textAlign: 'center' }}>{item.scores}</td>
              <td>{this.getTimeDuration(item.spend_time)}</td>
            </tr>
          ))}
          <tr>
            <td>{t('v2:total')}</td>
            <td></td>
            <td style={{ textAlign: 'center' }}>{totalScore}</td>
            <td>{this.getTimeDuration(totalSpendingTime)}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  handleSearchType = type => {
    const {
      reports: {
        skillJourney: {
          data: { participants }
        }
      }
    } = this.props
    if (type === 'name') {
      this.setState({
        searchType: 'searchByname'
      })
    } else if (type === 'id') {
      this.setState({
        searchType: 'searchById'
      })
    } else if (type === 'email') {
      this.setState({
        searchType: 'searchByemail'
      })
    } else if (type === 'group_name') {
      this.setState({
        searchType: 'searchByGroup'
      })
    } else {
      this.setState({
        searchType: 'search',
        participantsData: participants
      })
    }
  }

  onExpand = (expanded, record) => {
    if (this.state.expendIdNo===record.id) {
      this.setState({
        expendIdNo: 0
      })
    } else {
      this.setState({
        expendIdNo: record.id
      })
    }
    var keys = []
    if (expanded) {
      keys.push(record.id)
    }
    this.setState({ expandedRowKeys: keys })
  }

  download = () => {
    const {
      reports: {
        skillJourney: {
          data: { participants = [] }
        }
      }
    } = this.props
    console.log('download', this.props)
    const {
      reports: {
        skillJourney: { data }
      }
    } = this.props
    let dataCSV = []
    let header = []

    data &&
      dataCSV.push({
        title: data.title,
        username: '',
        date: '',
        resources: data.resources.length,
        score: '',
        pass_status: '',
        time_spent: ''
      })
    participants &&
      participants.forEach(item => {
        dataCSV.push({
          title: '',
          username: item.username,
          date: moment(item.created_at).format('YYYY-MM-DD'),
          resources: '',
          score: item.scores ? item.scores.toFixed() : 0,
          pass_status: item.status === 'fail' ? 'Not Passed' : 'Passed',
          time_spent: item.spending_time
            ? `${moment
                .duration(item.spending_time)
                .asMinutes()
                .toFixed(2)} minutes`
            : 'N/A'
        })
      })

    header = [
      'Title',
      'Username',
      'Created Date',
      'Resources',
      'Score',
      'Pass Status',
      'Time Spent In Course'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'skill-journey-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'skill-journey-report.csv')
      ele.click()
    }
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    this.setState({
      page: page
    })
  }

  onSearchWithType = val => {
    const { searchType, groups } = this.state
    const {
      reports: {
        skillJourney: {
          data: { participants }
        }
      }
    } = this.props

    if (val === '') {
      this.setState({ participantsData: participants })
      return
    }

    if (searchType === 'search') {
      const searchResult = participants.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByname') {
      const searchResult = participants.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByGroup' && groups.length > 0) {
      const searchResult = participants.filter(item => {
        return (
          item.group && item.group.toLowerCase().includes(val.toLowerCase())
        )
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchById') {
      const searchResult = participants.filter(item => {
        return val.includes(item.user_id)
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByemail') {
      const searchResult = participants.filter(item => {
        return item.email.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    }
  }

  handleGroupSearch = value => {
    this.onSearchWithType(value)
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props
    await this.props.getSkillJourneyReport(id)

    console.log('skilldetailresponse', this.props)
    const {
      reports: {
        skillJourney: { data }
      }
    } = this.props
    console.log('skilldetailresponse2', this.props)
    this.setState({
      loading: false,
      participantsData: data.participants
    })
    this.fetchGroups()
  }

  customExpandIcon = props => {
    return this.state.expendIdNo===props.record.id ? (
      <Icon type="up" />
    ) : (
      <Icon type="down" />
    )
  }

  callbackTabClicked = (key, event) => {
    if (key === '1') {
      this.props.history.push('/reports', 'user')
    } else if (key === '3') {
      this.props.history.push(`/reports`, 'marketplace')
    }
  }

  render() {
    const {
      loading,
      page,
      participantsData,
      pageSize,
      searchType,
      groups
    } = this.state

    const {
      t,
      reports: {
        skillJourney: {
          data: {
            participants,
            created_at,
            cover,
            passed,
            failed,
            resources,
            description,
            pass_score
          },
          fetch
        }
      }
    } = this.props

    // console.log('data', this.props)
    console.log('datadetail', this.props.reports.skillJourney)

    const dataList = participantsData.slice(
      this.createOffset(page, pageSize),
      this.createOffset(page, pageSize) + 10
    )

    console.log('datalist', dataList)

    return (
      <Fragment>
        {fetch || loading ? (
          <Spinner />
        ) : (
          <div className="content-warp">
            <div className="report-warp" style={{ direction: 'ltr' }}>
              <CustomTabs
                defaultActiveKey="0"
                onTabClick={this.callbackTabClicked}
                // tabBarGutter={15}
                tabBarExtraContent={
                  <ReportHeader download={this.download} isDateActive />
                }
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <TypeSelect defaultValue="skill_journey" />
                  <div className="custom-table">
                    <Row>
                      <Col span={8}>
                        <Row>
                          <BackButton
                            onClick={() =>
                              this.props.history.push('/reports/skill-journey')
                            }
                            size="large"
                            style={{
                              // width: '92%',
                              marginTop: '20px',
                              marginLeft: '15px'
                            }}
                          >
                            <LeftOutlined />
                            {t('v4:back')}
                          </BackButton>
                        </Row>

                        <Card
                          className={
                            // list.length === 0
                            //   ? 'custom-card-empty'
                            //   : 'custom-card'
                            'custom-card'
                          }
                        >
                          <img
                            className="table-img"
                            src={cover.link}
                            // onClick={this.onClickImage}
                            style={{ cursor: 'pointer' }}
                            alt=""
                          />
                          <p className="custom-description">{description}</p>
                          <Divider
                            type="horizontal "
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeigth: 'bold', color: 'black' }}
                            >
                              {t('reports:created_date').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {moment(created_at).format('YYYY/MM/DD')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:participant').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {participants.length}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:minimum_pass_score').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {pass_score}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:passed').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {passed}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:failed').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {failed}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:resources').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {resources.length}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          dataSource={dataList}
                          expandedRowRender={record =>
                            this.renderSkillJourneyTable(record)
                          }
                          onExpand={this.onExpand}
                          expandIcon={this.customExpandIcon}
                          expandedRowKeys={this.state.expandedRowKeys || []}
                          expandRowByClick
                          expandIconAsCell={false}
                          expandIconColumnIndex={6}
                          // pagination={true}
                          columns={columns(t)}
                          width
                          style={{
                            width: '92%',
                            // marginTop: '30px',
                            marginLeft: '35px'
                          }}
                          title={() => (
                            <>
                              <Row
                                style={{
                                  marginLeft: '30px',
                                  marginTop: '3px'
                                  // marginBottom: '13px'
                                }}
                              >
                                <Col span={13}>
                                  <div className="reports-user-search">
                                    {searchType === 'searchByGroup' &&
                                      groups.length > 0 && (
                                        <ReportSelect
                                          onChange={this.handleGroupSearch}
                                          className="rounded-select"
                                          dropdownStyle={{
                                            borderRadius: '9px'
                                          }}
                                          arrowColor
                                          defaultValue={t('v4:select_group')}
                                        >
                                          {groups.map(group => {
                                            return (
                                              <Select.Option
                                                key={group.id}
                                                value={group.title}
                                                className="select-option-user"
                                              >
                                                {group.title}
                                              </Select.Option>
                                            )
                                          })}
                                        </ReportSelect>
                                      )}

                                    {(searchType !== 'searchByGroup' ||
                                      (searchType === 'searchByGroup' &&
                                        groups.length === 0)) && (
                                      <Search
                                        placeholder={t('v4:search_people')}
                                        onChange={e =>
                                          this.onSearchWithType(e.target.value)
                                        }
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col className="report-title" span={5} push={2}>
                                  {t('v4:total')} {participants.length}{' '}
                                  {t('reports:participants').toUpperCase()}
                                </Col>
                                <Col span={6} style={{ marginTop: '14px' }}>
                                  <PaginationRightAligned>
                                    <Pagination
                                      total={participantsData.length}
                                      current={page}
                                      pageSize={pageSize}
                                      onChange={this.onChangePagination}
                                      size="small"
                                      simple
                                    />
                                  </PaginationRightAligned>
                                </Col>
                              </Row>
                              <Divider style={{ margin: '0px' }} />
                            </>
                          )}
                        ></ReportTable>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab={t('reports:user_report')} key="1"></TabPane>
                <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
              </CustomTabs>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('report')(Preview)
