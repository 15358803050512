import React, { useState } from 'react'
import { Button, Input, message, Drawer } from 'antd'
import './ImageGeneration.scss'
import { ImagePreview } from '../../../../components'
import CommentInput from '../CommentInput'

const { TextArea } = Input

const defaultScenes = [
  {
    id: 1,
    title: 'Scene 01',
    description: 'Big city at night',
    settings: {
      location: `Big city with a reporter's van on the street`,
      lighting: 'Dark',
      weather: 'Clear'
    },
    shots: [
      {
        id: 1,
        src: 'https://picsum.photos/400/300',
        prompt: 'A news reporter reporting on latest news',
        shotType: 'Medium-Shot'
      },
      {
        id: 2,
        src: 'https://picsum.photos/401/300',
        prompt: 'A big screen showing news',
        shotType: 'Close-Up'
      },
      {
        id: 3,
        src: '',
        prompt: 'Loads of police cyberpunk robots running on the street',
        shotType: ''
      }
    ]
  },
  {
    id: 2,
    title: 'Scene 02',
    description: 'Cyberpunk alley',
    settings: {
      location: 'Alleyway with neon signs',
      lighting: 'Dim',
      weather: 'Rainy'
    },
    shots: [
      {
        id: 1,
        src: 'https://picsum.photos/402/300',
        prompt: 'A detective walking through the alley',
        shotType: 'Wide-Shot'
      },
      {
        id: 2,
        src: '',
        prompt: 'A close-up of neon lights reflected on puddles',
        shotType: 'Close-Up'
      }
    ]
  }
]

const ImageGeneration = () => {
  const [scenes, setScenes] = useState(defaultScenes)
  const [selectedSettings, setSelectedSettings] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)

  const handleRegenerateImage = (sceneId, shotId) => {
    message.success(`Scene ${sceneId}, Shot ${shotId} regenerated!`)
  }

  const handleEditSettings = (sceneId, shotId) => {
    const scene = scenes.find(s => s.id === sceneId)
    const shot = scene.shots.find(sh => sh.id === shotId)

    setSelectedSettings({
      sceneId,
      shotId,
      ...scene.settings,
      ...shot
    })
    setDrawerVisible(true)
  }

  const updateSettings = (key, value) => {
    setSelectedSettings(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const saveSettings = () => {
    setScenes(prevScenes =>
      prevScenes.map(scene => {
        if (scene.id === selectedSettings.sceneId) {
          return {
            ...scene,
            settings: {
              location: selectedSettings.location,
              lighting: selectedSettings.lighting,
              weather: selectedSettings.weather
            },
            shots: scene.shots.map(shot =>
              shot.id === selectedSettings.shotId
                ? {
                    ...shot,
                    prompt: selectedSettings.prompt,
                    shotType: selectedSettings.shotType
                  }
                : shot
            )
          }
        }
        return scene
      })
    )
    setDrawerVisible(false)
  }

  return (
    <div className="image-generation-container">
      <div className="header">
        <h1>Image Generation</h1>
        <p>Create images for your video</p>
      </div>
      {scenes.map(scene => (
        <div key={scene.id} className="scene-container">
          {/* Left Panel */}
          <div className="left-panel">
            <h2>{scene.title}</h2>
            <TextArea
              value={scene.description}
              rows={2}
              onChange={e => {
                const newDescription = e.target.value
                setScenes(prevScenes =>
                  prevScenes.map(s =>
                    s.id === scene.id
                      ? { ...s, description: newDescription }
                      : s
                  )
                )
              }}
            />

            <div className="scene-settings">
              <div className="scene-settings-item">
                <label>Location</label>
                <Input value={scene.settings.location} />
              </div>
              <div className="scene-settings-item">
                <label>Lighting</label>
                <Input value={scene.settings.lighting} />
              </div>
              <div className="scene-settings-item">
                <label>Weather</label>
                <Input value={scene.settings.weather} />
              </div>
            </div>
          </div>

          {/* Right Panel */}
          <div className="right-panel">
            <div className="shots-grid">
              {scene.shots.map((shot, index) => (
                <div key={`${scene.id}-${shot.id}`} className="shot-card">
                  <h3 className="shot-title">Shot {index + 1}</h3>
                  <div className="image-wrapper">
                    <ImagePreview src={shot.src} alt={`Shot ${shot.id}`} />
                  </div>
                  <CommentInput
                    text={shot.prompt}
                    placeholder="Write your image prompt..."
                  />
                  <Input value={shot.shotType} placeholder="Shot Type" />
                  <div className="card-actions">
                    <Button
                      size="small"
                      onClick={() => handleRegenerateImage(scene.id, shot.id)}
                    >
                      Regenerate Image
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleEditSettings(scene.id, shot.id)}
                    >
                      Shot Editor
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      {/* Settings Drawer */}
      <Drawer
        title="Edit Shot Settings"
        placement="right"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={400}
      >
        {selectedSettings && (
          <div className="settings-drawer-content">
            <div className="setting-group">
              <label>Location</label>
              <Input
                value={selectedSettings.location}
                onChange={e => updateSettings('location', e.target.value)}
              />
            </div>

            <div className="setting-group">
              <label>Lighting</label>
              <Input
                value={selectedSettings.lighting}
                onChange={e => updateSettings('lighting', e.target.value)}
              />
            </div>

            <div className="setting-group">
              <label>Weather</label>
              <Input
                value={selectedSettings.weather}
                onChange={e => updateSettings('weather', e.target.value)}
              />
            </div>

            <div className="setting-group">
              <label>Prompt</label>
              <TextArea
                rows={3}
                value={selectedSettings.prompt}
                onChange={e => updateSettings('prompt', e.target.value)}
              />
            </div>

            <div className="setting-group">
              <label>Shot Type</label>
              <Input
                value={selectedSettings.shotType}
                onChange={e => updateSettings('shotType', e.target.value)}
              />
            </div>

            <Button
              type="primary"
              onClick={saveSettings}
              style={{ marginTop: 16 }}
            >
              Save Settings
            </Button>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default ImageGeneration
