import React, { Fragment, Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { message, Form } from 'antd'
import moment from 'moment'
import debounce from 'lodash/debounce'
import _ from 'lodash'
import { Tag } from '../../../components'
import { EventContainer } from './styled'
import Header from './components/Header'
import { CreateForm } from './components'
import { api, i18n } from '../../../services'
import { general } from '../../../constants'
import history from '../../../history'
import MarketPlaceForm from './components/internal/MarketPlaceForm'
import ZoomForm from './components/internal/ZoomForm'

class Create extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      isFetching: false,
      isSaving: false,
      coverId: null,
      coverLink: null,
      coverSize: null,
      coverName: '',
      validate: false,
      isLoading: false,
      current: {
        id: null,
        title: '',
        email: '',
        description: '',
        event_attendees: '',
        topics: [],
        topic_list: [],
        is_chat: true,
        is_poll: false,
        is_recording: false,
        has_breakout_room: false,
        participantNum: null,
        attendeeNum: null,
        poll: [
          {
            question: '',
            answers: ['']
          }
        ],
        marketplace: {
          category_id: null,
          video_link: '',
          price: null,
          course_description: '',
          course_obj: '',
          course_outline: '',
          group_id: null,
          is_free: false,
          no_of_video: null,
          username: ''
        },
        encoder: '',
        host_emails: [],
        host_ids: [],
        attachments: [],
        is_transcribed: false,
        transcribe_locale: ''
      },
      tag: { value: 'conference', text: props.t('v3:private_conference') },
      isEventMarketPlace: false,
      modalOpen: false,
      toSelectFile: false,
      attachmentIds: [],
      filenames: [],
      pdfFiles: [],
      is_host_only: true,
      encoderType: 'conference',
      selectedType: [0],
      eventTime: null,
      eventDuration: 20,
      selectedOrganizations: [],
      selectedGroups: [],
      checkedTerms: false,
      error: null,
      error_message: '',
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: [],
      isDisable: true,
      isPublish: false,
      isOverLimit: false,
      errorData: {
        title: '',
        subTitle: ''
      },
      isAdminOnly: false,
      isSendMail: true,
      is_ai_enabled: false,
      is_live_assessment: false,
      live_assessments: [],
      isSpecific: false,
      emailList: [],
      emails: [],
      selectedValue: '',
      isMarketPlaceEnabled: false,
      topicValue: [],
      error_msg_ai: '',
      breakoutEnable: false,
      cert_id: null,
      badge_id: null,
      pass_score: '',
      hasZoomIntegrated: false,
      checkingZoomStatus: false,
      allocated_time_slots: [],
      shouldHideNonMarketplaceFields: false,
      categories: []
    }

    this.handleInsertUsers = debounce(this.handleInsertUsers, 1000)
  }

  componentDidMount() {
    const {
      clearUploadList,
      match: {
        params: { id }
      },
      user: {
        info: { active_organization_id }
      }
    } = this.props
    const {
      tag: { value }
    } = this.state

    clearUploadList()
    this.fetchCategories()
    if (id) {
      this.setState({ isFetching: true }, () => {
        this.getEventsData()
      })
    } else {
      this.setState({ isFetching: false })
    }

    if (value === 'conference') {
      this.setState({
        selectedOrganizations: [active_organization_id],
        selectedGroups: [],
        selectedType: [2]
      })
    } else {
      this.setState({
        selectedOrganizations: []
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { transcribeLocale, is_ai_enabled, tag } = this.state
    if (
      (prevState.transcribeLocale !== transcribeLocale && transcribeLocale) ||
      prevState.is_ai_enabled !== is_ai_enabled
    ) {
      const cleanLocale =
        transcribeLocale && transcribeLocale !== ''
          ? transcribeLocale.split('-')
          : []
      if (
        !cleanLocale.includes('en') && // english
        !cleanLocale.includes('de') && // germany
        !cleanLocale.includes('fr') && // french
        !cleanLocale.includes('ko') && // korean
        !cleanLocale.includes('th') && // thai
        !cleanLocale.includes('ru') && // russian
        !cleanLocale.includes('es') && // spanish
        is_ai_enabled
      ) {
        this.setState({
          error_msg_ai:
            'AI service available in English, German, French, Korean, Thai, Russian & Spanish only'
        })
        return
      }
      return this.setState({ error_msg_ai: '' })
    }
    if (prevState.tag !== tag && tag.value === 'zoom_meeting') {
      this.getZoomStatus()
    }
  }

  fetchCategories = () => {
    api.courses
      .fetchCategory()
      .then(res => {
        this.setState({ categories: res.data })
      })
      .catch(() => {
        message.error('Error in fetching categories')
      })
  }
  getZoomStatus = () => {
    this.setState({ checkingZoomStatus: true }, () => {
      api.user
        .getIntegrationStatus()
        .then(res => {
          this.setState({
            checkingZoomStatus: false,
            hasZoomIntegrated: res.data ? res.data.integrated : false
          })
        })
        .catch(() =>
          this.setState({ hasZoomIntegrated: false, checkingZoomStatus: false })
        )
    })
  }

  setCover = cover => {
    this.setState({
      coverId: cover.id,
      coverSize: cover.file_size,
      coverName: cover.filename,
      coverLink: cover.link
    })
    return
  }

  getDefaultEncoder = type => {
    if (type === 'private_conference') return 'conference'
    else if (type === 'public_conference') return 'agora_conference'
    else if (type === 'coaching_session') return 'peer2peer'
    else if (type === 'assessment') return 'live_assessment'
    else return type
  }

  getEventsData = () => {
    this._isMounted = true
    const id = this.props.match.params.id

    api.events
      .getById(id)
      .then(response => {
        if (this._isMounted) {
          if (response.data.event_type === 'discussion_room') {
            history.push(`/podcast/${id}/edit`)
            return
          }
          if (response.data.cover) {
            this.setCover(response.data.cover)
          }
          const {
            data: {
              start_date_time,
              duration,
              is_transcribed,
              transcribe_locale,
              topics,
              attachments,
              encoder,
              setting,
              host_emails,
              has_breakout_room,
              is_live_assessment,
              live_assessments,
              attendee_id,
              attendee_email,
              cert_id,
              badge_id,
              pass_score,
              event_type
            }
          } = response
          const defaultEncoder = this.getDefaultEncoder(event_type)

          const isFromPhone =
            encoder === 'wowza_gocoder' || encoder === 'stream_from_phone'

          const { t } = this.props
          const tagsData = [
            { value: 'conference', text: t('v3:private_conference') },
            { value: 'webinar', text: t('v3:webinar') },
            { value: 'agora_conference', text: t('v3:public_conference') },
            { value: 'peer2peer', text: t('v4:coaching_session') },
            { value: 'live_assessment', text: t('v2:live_assessment') }
          ]
          this.setState(
            {
              current: {
                ...this.state.current,
                ...response.data
              },
              eventTime: start_date_time ? moment(start_date_time) : null,
              eventDuration: duration || 20,
              isFetching: false,
              toTranscribe: is_transcribed,
              breakoutEnable: has_breakout_room,
              transcribeLocale: transcribe_locale,
              tag:
                defaultEncoder === 'webinar'
                  ? tagsData[1]
                  : defaultEncoder === 'conference'
                  ? tagsData[0]
                  : defaultEncoder === 'peer2peer'
                  ? tagsData[3]
                  : defaultEncoder === 'agora_conference'
                  ? tagsData[2]
                  : defaultEncoder === 'live_assessment'
                  ? tagsData[4]
                  : defaultEncoder === 'zoom_meeting'
                  ? tagsData[5]
                  : isFromPhone
                  ? tagsData[1]
                  : {},
              isEdit: true,
              hasError: false,
              encoderType: defaultEncoder,
              emails: host_emails,
              is_live_assessment,
              live_assessments,
              attendee_id,
              attendee_email,
              cert_id,
              badge_id,
              pass_score,
              shouldHideNonMarketplaceFields:
                setting && setting.publish_option === 'marketplace'
            },
            () => {
              this.getOldTopics(topics)
              this.getAttachmentDetail(attachments)
              if (
                event_type === 'webinar' ||
                event_type === 'public_conference' ||
                event_type === 'coaching_session'
              ) {
                this.showErrorMessageForMarketPlace()
              }
              this.getOldSetting(response.data)
            }
          )
        }
      })
      .catch(err => {
        console.log('error event by id', err)
        this.setState({ hasError: true, isEdit: true, isFetching: false })
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getOldSetting = courseById => {
    const { setting } = courseById
    const {
      current: { encoder },
      emails: hostEmails
    } = this.state
    if (setting) {
      const {
        group_ids,
        publish_option,
        organization_ids,
        emails,
        is_admin_only: isAdminOnly,
        is_host_only
      } = setting

      if (publish_option === general.ORGANIZATION_AND_MARKETPLACE) {
        this.setState({
          selectedType: [2, 4]
        })
      } else if (publish_option === general.ORGANIZATION) {
        this.handleSelectedType(2)
      } else if (publish_option === general.ORGANIZATION_GROUP) {
        this.handleSelectedType(3)
      } else if (publish_option === general.MARKETPLACE) {
        this.handleSelectedType(4)
      } else if (publish_option === general.PUBLIC) {
        this.handleSelectedType(1)
      }

      const isLiveAssessment = encoder === 'live_assessment'
      const newEmails = isLiveAssessment ? hostEmails : emails

      this.handleInsertUsers(_.uniq(newEmails).join('\n'))

      this.setState({
        selectedOrganizations: organization_ids,
        selectedGroups: group_ids,
        emails: newEmails,
        selectedValue: newEmails,
        isAdminOnly,
        is_host_only: is_host_only
      })
    }
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching && !_.isEmpty(topicList)) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        topicValue: newValue
      })
    }
  }

  createOption = topic => {
    return {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
  }

  handleTopicChange = topics => {
    this.setState(
      {
        topicValue: topics
      },
      () => {
        this.handleParam()
      }
    )
  }

  getAttachmentDetail = ids => {
    if (ids && ids.length > 0) {
      return ids.forEach(id => {
        api.library.getMediaInfoById(id).then(response => {
          this.setPDF(response.data)
        })
      })
    }
  }

  showErrorMessageForMarketPlace = async () => {
    const { t, form } = this.props
    const { current } = this.state
    await form.validateFields(async (err, values) => {
      const {
        marketplace: {
          category_id,
          video_link,
          is_free,
          price,
          course_description,
          course_obj,
          course_outline
        }
      } = current

      let isFree =
        typeof this.state.is_free === 'boolean'
          ? this.state.is_free
          : current.marketplace
          ? is_free
          : false

      const data = {
        category_id: category_id ? category_id : values.category,
        video_link: video_link ? video_link : values.video_link,
        price: !isFree && price ? price : Number(values.price) || 0,
        course_description,
        course_obj,
        course_outline
      }

      let errorData = Object.keys(data).filter(k => {
        if (data[k] === '' || data[k] === undefined || data[k] === null) {
          return true
        } else {
          return false
        }
      })

      if (errorData.length) {
        message.warning(t('v4:not_complete'))
        await this.setState({
          isMarketPlaceEnabled: false
        })
      } else {
        message.success(t('v4:complete'))
        await this.setState({
          isMarketPlaceEnabled: true
        })

        // if (event_type === 'coaching_session') {
        //   this.handleSelectedType(4)
        // }
      }
    })
  }

  handleToggleMarketPlace = () =>
    this.setState(
      prevState => ({ isEventMarketPlace: !prevState.isEventMarketPlace }),
      () => {
        this.showErrorMessageForMarketPlace()
      }
    )

  handleTags = value => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props

    this.setState(
      () => ({ tag: value }),
      () => {
        if (value.value === 'webinar') {
          return this.setState(
            {
              is_host_only: false,
              encoderType: 'conference',
              selectedOrganizations: [active_organization_id]
            },
            () => {
              this.handleSelectedType(2)
            }
          )
        }
        if (value.value === 'conference') {
          return this.setState(
            {
              is_host_only: true,
              encoderType: 'conference',
              selectedOrganizations: [active_organization_id],
              selectedGroups: []
            },
            () => {
              this.handleSelectedType(2)
            }
          )
        }
        if (value.value === 'peer2peer') {
          this.setState(
            {
              is_host_only: true,
              encoderType: value.value,
              emails: [],
              selectedOrganizations: [],
              selectedGroups: [],
              isMarketPlaceEnabled: false
            },
            () => {
              this.handleSelectedType(4)
            }
          )
        }
        if (value.value === 'live_assessment') {
          return this.setState(
            {
              is_host_only: true,
              encoderType: value.value,
              selectedOrganizations: [active_organization_id],
              isMarketPlaceEnabled: false
            },
            () => {
              this.handleSelectedType(2)
            }
          )
        }
        if (value.value === 'agora_conference') {
          return this.setState(
            {
              is_host_only: false,
              encoderType: 'agora_conference',
              selectedOrganizations: [active_organization_id]
            },
            () => {
              this.handleSelectedType(2)
            }
          )
        }

        if (value.value === 'zoom_meeting') {
          return this.setState(
            {
              is_host_only: true,
              encoderType: 'zoom_meeting',
              selectedOrganizations: [active_organization_id],
              isMarketPlaceEnabled: false
            },
            () => {
              this.handleSelectedType(2)
            }
          )
        }
      }
    )
    return
  }

  getLastOption = () => {
    const { encoderType, isMarketPlaceEnabled } = this.state
    const coaching = encoderType==='peer2peer'
    if (this.checkSelectedType(2) && this.checkSelectedType(4)) {
      return general.ORGANIZATION_AND_MARKETPLACE
    } else if (this.checkSelectedType(1)) {
      return general.PUBLIC
    } else if (coaching && !isMarketPlaceEnabled) {
      return general.ORGANIZATION
    } else if (this.checkSelectedType(3)) {
      return general.ORGANIZATION_GROUP
    } else if (this.checkSelectedType(4)) {
      return general.MARKETPLACE
    } else if (this.checkSelectedType(2)) {
      return general.ORGANIZATION
    }
  }

  handleCheckBreakRoomChange = checked => {
    this.setState(prevState => ({
      current: {
        ...prevState.current,
        has_breakout_room: checked
      },
      breakoutEnable: checked
    }))
  }

  handleCheckChange = (field, checked) => {
    // console.log('if recording value', field, checked);
    this.setState(
      prevState => {
        if (field === 'is_recording' && !checked) {
          return {
            current: {
              ...prevState.current,
              [field]: checked,
              is_transcribed: false,
              transcribe_locale: ''
            },
            toTranscribe: false,
            transcribeLocale: '',
            is_ai_enabled: false
          }
        }
        return {
          current: {
            ...prevState.current,
            [field]: checked
          }
        }
      },
      () => {
        this.handleParam()
      }
    )
    return
  }

  handleAttendeeEmailChange = values => {
    this.setState(
      prevState => ({
        current: {
          ...prevState.current,
          host_emails: values
        },
        emails: values
      }),
      async () => {
        await this.handleInsertUsers(_.uniq(this.state.emails).join('\n'))
        this.handleForm()
      }
    )
    return
  }

  handleParam = _.debounce((param, value) => {
    if (param === 'host') {
      return
    }
    console.log('handleparamvalue', value)
    this.handleForm()
  }, 1000)

  handlePoll = value => {
    console.log(`handlepollworked`, value)
    this.setState(
      prevState => ({
        current: {
          ...prevState.current,
          is_poll: value
        }
      }),
      () => {
        this.handleForm()
      }
    )
    return
  }

  handleAttendeeOfAssessment = ({ id, email }) => {
    this.setState(
      {
        attendee_id: id,
        attendee_email: email
      },
      () => {
        this.handleForm()
      }
    )
  }

  handleForm = _.debounce(() => {
    const { isSaving } = this.state
    if (isSaving) {
      this.handleForm()
    } else {
      this.handleSubmitForm()
    }
  }, 1000)

  handleSubmitForm = () => {
    const {
      form,
      user: {
        info: { username, active_organization_id, organization }
      }
    } = this.props

    const {
      current,
      current: { marketplace },
      coverId,
      topicValue,
      emails,
      attachmentIds,
      isEventMarketPlace,
      encoderType,
      selectedOrganizations,
      selectedGroups,
      eventTime,
      eventDuration,
      isAdminOnly: is_admin_only,
      is_ai_enabled,
      is_host_only,
      toTranscribe,
      transcribeLocale,
      breakoutEnable,
      attendee_email,
      attendee_id,
      tag,
      cert_id,
      checkedTerms,
      badge_id,
      pass_score,
      marketplaceOption
    } = this.state

    const {
      category_id,
      video_link,
      is_free,
      price,
      course_description,
      course_obj,
      course_outline
    } = marketplace
    const id = this.props.match.params.id || current.id || null
    this.setState({
      validate: true
    })

    const removedSpaceEmail = _.map(emails, email => email.trim().toLowerCase())

    const encoder =
      encoderType === 'conference' ? 'chime_conference' : encoderType

    let privacy = {
      is_public: this.checkSelectedType(1),
      is_admin_only: this.checkSelectedType(1) ? is_admin_only : false,
      organization_ids:
        encoderType === 'peer2peer'
          ? [active_organization_id]
          : selectedOrganizations,
      group_ids: selectedGroups,
      emails: _.uniq([...removedSpaceEmail]),
      publish_option: this.getLastOption(),
      is_host_only: is_host_only,
      is_marketplace: this.checkSelectedType(4)
    }

    form.validateFields((err, values) => {
      if (!coverId) {
        return
      }

      if (_.isEmpty(topicValue)) {
        return
      }

      if (!err || (err && isEventMarketPlace)) {
        const liveAssessments =
          (values.questions && values.questions.filter(item => item !== '')) ||
          []
        this.setState({ live_assessments: liveAssessments })
        let isFree =
          typeof this.state.is_free === 'boolean'
            ? this.state.is_free
            : current.marketplace
            ? is_free
            : false

        const eventData = {
          attendee_id: attendee_id,
          attendee_email: attendee_email,
          is_transcribed: toTranscribe,
          transcribe_locale: transcribeLocale
        }

        const eventType =
          tag && tag.value === 'conference'
            ? 'private_conference'
            : tag.value === 'agora_conference'
            ? 'public_conference'
            : tag.value === 'peer2peer'
            ? 'coaching_session'
            : tag.value === 'live_assessment'
            ? 'assessment'
            : tag.value

        const data = {
          title: values.title ? values.title : current.title,
          description: values.description
            ? values.description
            : current.description,
          topics: topicValue ? _.map(topicValue, 'id') : current.topics,
          host: values.host,
          cover_id: coverId,
          is_chat: current.is_chat || values.is_chat,
          is_poll: current.is_poll || values.is_poll,
          ...((current.is_poll || values.is_poll) && { poll: current.poll }),
          is_recording: current.is_recording || values.is_recording,
          has_breakout_room: breakoutEnable,
          start_date_time: eventTime,
          duration: Number(eventDuration),
          no_of_people_conf: Number(current.no_of_people_conf),
          isSendMail: false,
          is_ai_enabled: is_ai_enabled,
          privacy,
          is_live_assessment: encoderType === 'live_assessment',
          live_assessments: liveAssessments,
          marketplace: {
            category_id: values.category || category_id,
            video_link: values.video_link || video_link,
            is_free: isFree,
            price: Number(values.price) || price || 5,
            username: username,
            course_description: values.course_description || course_description,
            course_obj: values.course_obj || course_obj,
            course_outline: values.course_outline || course_outline,
            group_id: null,
            is_shared_profile: values.is_shared_profile,
            bio: organization ? organization.bio : null,
            linkedin: organization ? organization.linkedin : null
          },
          event_type: eventType,
          isSendMailToHost: false,
          encoder_type: encoder,
          host_emails: emails || current.host_emails,
          host_ids: current.host_ids,
          attachments: attachmentIds || current.attachments,
          marketplace_option: marketplaceOption,
          cert_id: cert_id,
          badge_id: badge_id,
          pass_score: Number(pass_score),
          ...eventData
        }

        if (current.cover_id && id) {
          this.setState({
            isSaving: true,
            ...(checkedTerms && { isDisable: false })
          })
          api.events
            .updateById(id, data)
            .then(response => {
              const {
                data: { poll, ...rest }
              } = response
              this.setState({
                isLoading: false,
                isSaving: false,
                current: {
                  ...this.state.current,
                  ...rest
                }
              })
            })
            .catch(error => {
              message.error(error.response.data.errorMessage)
              const {
                status,
                data: { errorMessage }
              } = error.response
              if (status === 422) {
                const message = errorMessage.split(':')
                const splitMessage = _.last(message).split('.')
                this.setState({
                  isDisable: true,
                  isPublish: false,
                  errorData: {
                    title: splitMessage[0],
                    subTitle: splitMessage[1]
                  },
                  isOverLimit: true,
                  isSaving: false,
                  isLoading: false
                })
              }
              if (status === 400) {
                message.error(errorMessage)
                this.setState({
                  isDisable: true,
                  isPublish: false,
                  isSaving: false,
                  isLoading: false
                })
              }
            })
        } else {
          this.setState({
            isSaving: true,
            ...(checkedTerms && { isDisable: false })
          })
          api.events
            .create({
              ...data,
              event_type: eventType
            })
            .then(response => {
              if (response && response.data && response.data.id) {
                // this.changeEventRoute(response.data.id)
                this.setState({
                  isSaving: false,
                  isLoading: false,
                  current: {
                    ...this.state.current,
                    ...response.data
                  }
                })
              }
            })
            .catch(({ response }) => {
              const {
                status,
                data: { errorMessage }
              } = response
              if (status === 422) {
                const message = errorMessage.split(':')
                const splitMessage = _.last(message).split('.')
                this.setState({
                  isDisable: true,
                  isPublish: false,
                  errorData: {
                    title: splitMessage[0],
                    subTitle: splitMessage[1]
                  },
                  isOverLimit: true,
                  isSaving: false,
                  isLoading: false
                })
              }
              if (status === 400) {
                message.error(errorMessage)
                this.setState({
                  isDisable: true,
                  isPublish: false,
                  isSaving: false,
                  isLoading: false
                })
              }
            })
        }
      }
    })
  }

  changeEventRoute = id => {
    if (id) {
      window.history.replaceState(null, null, `/events/${id}/edit`)
    }
  }

  checkedPublishButtonDisabling = () => {
    this.setState({
      isDisable: true,
      isPublish: true
    })
  }

  checkFinish = (startDataTime, duration) => {
    const {
      current: { setting_id }
    } = this.state
    const isAllowPermission = this.getUserRole()
    if (isAllowPermission) {
      if (startDataTime) {
        const newTime = moment(startDataTime).format('LLLL')
        const diffMinutes = moment().diff(newTime, 'minutes')
        const newDuration = duration || 10
        const checkInterval = diffMinutes - newDuration
        const scheduledTime = moment(startDataTime).format('LLLL')
        const isPastEvent = moment().diff(scheduledTime, 'minutes') > 0
        if (!setting_id) {
          return true
        } else {
          if (isPastEvent) {
            return checkInterval <= 0
          } else {
            return true
          }
        }
      }
      return true
    } else {
      return false
    }
  }

  getUserRole = () => {
    const {
      user: {
        info: { isAdmin, isSuperAdmin }
      }
    } = this.props
    const { is_finished: isFinished } = this.state.current
    if (!isFinished) {
      return isAdmin || isSuperAdmin
    } else {
      return !isFinished
    }
  }

  handleInsertFile = ({ rows: privateData }, publicMedia, orgMedia) => {
    let tempData = privateData

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData = [] } = orgMedia
      tempData = [...privateData, ...orgData]
    }
    let array = tempData
      .filter(t => t.isSelected)
      .map(file => this.setPDF(file))

    Promise.all(array).then(() => {
      this.handleParam()
      this.setState({ toSelectFile: false, modalOpen: false })
    })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []

    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    console.log('media check data', tempData)

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  handleFileInsert = item => {
    this.setCover(item)
    this.setState(
      {
        modalOpen: false,
        selectedItem: null
      },
      () => {
        this.handleParam()
      }
    )
  }

  handleRemoveSelected = () => {
    this.setState({ attachmentIds: [], filenames: [] })
  }

  toggleMediaModal = () => {
    // console.log('this.state.modalOpen', this.state.modalOpen)
    this.setState({ modalOpen: !this.state.modalOpen })
    return
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
    return
  }

  toggleFileModal = () => {
    this.setState({ toSelectFile: !this.state.toSelectFile })
    return
  }

  checkedCompletUpload = item => {
    const { uploadSuccess } = this.props.library
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  onDropPdf = dropped => {
    const file = dropped[0]
    const { pdfFiles } = this.state
    if (!file) {
      return
    }
    const { clearUploadList } = this.props
    const { upload } = this.props
    const newFiles = pdfFiles.concat(dropped)
    return this.setState(
      {
        toSelectFile: false,
        modalOpen: false,
        pdfFiles: newFiles
      },
      () => {
        dropped.forEach(file => {
          upload(file)
            .then(response => {
              this.setPDF(response)
            })
            .then(() => {
              this.handleParam()
              clearUploadList()
            })
        })
      }
    )
  }

  setPDF = file => {
    this.setState(prevState => {
      const filterIds = prevState.attachmentIds.filter(i => i !== file.id)
      const filterNames = prevState.filenames.filter(i => i.id !== file.id)
      return {
        attachmentIds: [...filterIds, file.id],
        filenames: [...filterNames, file]
      }
    })
  }

  handleCheckTranscribe = value => {
    this.setState(prevState => {
      if (!value) {
        this.clearAlert()
        return {
          toTranscribe: value,
          transcribeLocale: '',
          current: {
            ...prevState.current,
            is_transcribed: value,
            transcribe_locale: ''
          }
        }
      }
      return {
        toTranscribe: value,
        current: {
          ...prevState.current,
          is_transcribed: value
        }
      }
    })
  }

  handleChangeLocale = value => {
    this.setState(state => ({
      current: {
        ...state.current,
        transcribe_locale: value
      },
      transcribeLocale: value
    }))
  }

  onHandleDelete = id => {
    this.setState(
      state => {
        const filteredIds = state.attachmentIds.filter(i => i !== id)
        const filteredFiles = state.filenames.filter(i => i.id !== id)
        return {
          attachmentIds: filteredIds,
          filenames: filteredFiles
        }
      },
      () => {
        this.handleParam()
      }
    )
  }

  addPoll = () => {
    this.setState(state => ({
      current: {
        ...state.current,
        poll: [
          ...state.current.poll,
          {
            question: '',
            answers: ['']
          }
        ]
      }
    }))
  }

  addAnswer = rootIndex => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers ? [...item.answers, ''] : ['']
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  deleteAnswer = (rootIndex, index) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers.filter((a, j) => j !== index)
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  handleQuestion = (index, value) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === index) {
        item.question = value
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  handleAnswer = (rootIndex, index, value) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers.map((a, j) =>
          j === index ? (a = value) : a
        )
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  deletePoll = index => {
    this.setState(state => ({
      current: {
        ...state.current,
        poll: state.current.poll.filter((item, i) => i !== index)
      }
    }))
  }

  handleInsertUsers = value => {
    const {
      state: { tag },
      props: { t }
    } = this
    const emails = value.replace(/(\r\n|\n|\r)/gm, ',').split(',')
    const check = _.map(_.compact(emails), email =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    )
    const isSetValid = _.filter(check, item => item)

    console.log('Valeius', value)

    if (isSetValid.length === check.length) {
      if (tag.value === 'peer2peer' && emails.length > 1) {
        message.error(t('specific_email_validation_error'))
      } else if (tag.value === 'conference' && emails.length > 25) {
        message.error(t('v4:only_allowed_twenty_five_email'))
      } else if (tag.value === 'webinar' && emails.length > 5) {
        message.error(t('v4:only_allowed_five_email'))
      } else {
        this.setState({
          emails: value
            .trim()
            .split('\n')
            .filter(i => !!i)
        })
      }
    } else {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i)
      })
    }
  }

  handleSelectedDate = (field, value) => {
    const { checkedTerms } = this.state
    this.setState({
      [field]: field === 'eventDuration' ? this.getDurationValue(value) : value,
      isDisable: checkedTerms ? (value ? false : true) : true
    })
  }

  getDurationValue = value => {
    if (value <= 0) return null
    if (value > 300) return 300
    return value
  }

  handleSelectedType = id => {
    const { selectedType, encoderType } = this.state

    // selectedType changed to array to add the usecase of multiple select of marketplace and organization in publish settings

    const coaching = encoderType === 'peer2peer'

    // if (eventId && is_schedule_template && coaching) {
    //   this.setState({ selectedType: [4] })
    //   return
    // }

    if (
      !coaching &&
      (id === 1 || id === 3 || selectedType[0] === 1 || selectedType[0] === 3)
    ) {
      this.setState({
        selectedType: [id]
      })
    } else if (coaching) {
      this.setState({
        selectedType: selectedType[0] === id ? [] : [id]
      })
    } else if (selectedType.some(i => i === id)) {
      this.setState({
        selectedType:
          selectedType.length === 1
            ? [id]
            : selectedType.filter(item => item !== id)
      })
    } else {
      this.setState({
        selectedType: [...selectedType, id]
      })
    }
  }

  checkSelectedType = id => this.state.selectedType.some(item => item === id)

  disabledDate = current => current && current < moment().startOf('day')

  clearAlert = () => {
    this.setState({
      error: null,
      error_message: null,
      isOverLimit: false,
      error_msg_ai: ''
      // isDisable: false
    })
  }

  handleTimeError = type => {
    let errorMessage =
      type === 'date_time'
        ? 'date_time_error'
        : 'date_time_peer'
        ? 'date_time_error_peer'
        : 'duration_error'
    this.setState(
      {
        error: true,
        error_message: errorMessage
      },
      () => {
        message.error(general[errorMessage])
        setTimeout(() => {
          if (this.state.error) {
            this.clearAlert()
          }
        }, 10000)
      }
    )
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('emails')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false
    })
  }

  handleSendEmail = () => {
    this.setState(
      {
        isCheckModal: false,
        isNotExist: [],
        isNotInOrg: []
      },
      () => {
        this.handleCheckForm()
      }
    )
  }

  handleCheckForm = async () => {
    const {
      form,
      t,
      user: {
        info: { active_organization_id, bio, linkedin }
      }
    } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      emails,
      eventTime,
      eventDuration,
      current,
      current: { is_transcribed, transcribe_locale, marketplace, host },
      isAdminOnly: is_admin_only,
      isSendMail,
      is_host_only,
      encoderType,
      toTranscribe,
      transcribeLocale,
      attachmentIds,
      is_ai_enabled,
      live_assessments,
      breakoutEnable,
      attendee_id,
      attendee_email,
      cert_id,
      badge_id,
      pass_score,
      coverId,
      isMarketPlaceEnabled,
      topicValue
    } = this.state
    const nowPlus1 = moment().add(1, 'minutes')
    // we need plus 10 minutes after current time to make sure wowza is completly ready to live

    const isCoaching = encoderType === 'peer2peer'
    const isCoachingTemplate = isMarketPlaceEnabled && selectedType.includes(4)

    form.validateFields(async (err, values) => {
      if (!coverId) {
        message.error(t('v4:cover_is_required'))
        return
      }

      if (_.isEmpty(topicValue)) {
        message.error(t('v4:topic_is_required'))
        return
      }

      if (
        (current.is_poll || values.is_poll) === true &&
        _.isEmpty(current.poll[0].question)
      ) {
        message.error(i18n.t('v4:poll_required'))
        return
      }

      if (
        (current.is_poll || values.is_poll) === true &&
        _.isEmpty(current.poll[0].answers[0])
      ) {
        message.error(i18n.t('v4:poll_required'))
        return
      }

      if (!err) {
        if (this.checkFinish(eventTime, eventDuration)) {
          if (!isCoaching) {
            if (eventTime < nowPlus1) {
              this.handleTimeError('date_time_peer')
              return
            }

            if (
              encoderType !== 'live_assessment' &&
              this.checkSelectedType(2) &&
              _.isEmpty(selectedOrganizations)
            ) {
              message.error(i18n.t('errors:no_org'))
              return
            }

            if (
              encoderType !== 'live_assessment' &&
              this.checkSelectedType(3) &&
              _.isEmpty(selectedGroups)
            ) {
              message.error(i18n.t('errors:no_org_group'))
              return
            }

            if (!eventDuration) {
              this.handleTimeError('duration')
              message.error(i18n.t('errors:duration'))
              return
            }

            if (!eventTime) {
              message.error(i18n.t('errors:start_date'))
              return
            }

            if (!eventDuration) {
              return
            }
          } else if (isCoaching) {
            await this.checkCoachingScheduling()
          }
          if (is_transcribed && !transcribe_locale) {
            message.error(i18n.t('v4:select_transcribe_locale'))
            return
          }
        }

        if (
          !isCoachingTemplate &&
          _.isEmpty(emails) &&
          encoderType === 'peer2peer'
        ) {
          message.error(t('publishsettings:emails_validation_error'))
          return
        }

        if (current.is_poll === true && _.isEmpty(current.poll[0].question)) {
          message.error(i18n.t('v4:poll_required'))
          return
        }

        if (current.is_poll === true && _.isEmpty(current.poll[0].answers[0])) {
          message.error(i18n.t('v4:poll_required'))
          return
        }

        const encoder =
          encoderType === 'conference' ? 'chime_conference' : encoderType

        const removedSpaceEmail = _.map(emails, email =>
          email.trim().toLowerCase()
        )

        let privacy = {
          is_public: this.checkSelectedType(1),
          is_admin_only: this.checkSelectedType(1) ? is_admin_only : false,
          organization_ids:
            encoderType === 'peer2peer'
              ? [active_organization_id]
              : selectedOrganizations,
          group_ids: selectedGroups,
          emails: _.uniq([...removedSpaceEmail]),
          publish_option: this.getLastOption(),
          is_host_only: is_host_only,
          is_marketplace: this.checkSelectedType(4)
        }
        const data = {
          title: current.title,
          description: current.description,
          host: host ? JSON.stringify(host.id) : '',
          topics: current.topics,
          cover_id: current.cover_id,
          is_chat: current.is_chat,
          is_poll: current.is_poll,
          poll: current.is_poll ? current.poll : [],
          is_recording: current.is_recording,
          has_breakout_room: breakoutEnable,
          start_date_time: eventTime,
          duration: Number(eventDuration),
          no_of_people_conf: Number(current.no_of_people_conf),
          isSendMail,
          is_ai_enabled,
          is_live_assessment: encoderType === 'live_assessment',
          live_assessments,
          privacy,
          marketplace: {
            category_id: marketplace.category_id,
            price: marketplace.price || 5,
            is_free: marketplace.is_free,
            video_link: marketplace.video_link,
            course_description: marketplace.course_description,
            course_obj: marketplace.course_obj,
            course_outline: marketplace.course_outline,
            group_id: marketplace.group_id,
            no_of_video: marketplace.no_of_video,
            is_shared_profile: marketplace.is_shared_profile,
            username: marketplace.username,
            bio,
            linkedin
          },
          is_schedule_template: isCoachingTemplate,
          isSendMailToHost: true,
          encoder_type: encoder,
          attendee_id,
          attendee_email,
          host_emails: current.host_emails,
          host_ids: current.host_ids,
          attachments: current.attachments
            ? current.attachments
            : attachmentIds,
          is_transcribed: toTranscribe,
          transcribe_locale: transcribeLocale,
          cert_id,
          badge_id,
          pass_score: Number(pass_score)
        }

        console.log('data before update', data)

        if (!this.checkFinish(eventTime, eventDuration)) {
          delete data.start_date_time
          delete data.duration
        }

        const eventId = this.props.match.params.id || current.id || null

        this.setState(
          {
            isLoading: true
          },
          () => {
            api.events
              .updateById(eventId, data)
              .then(() => {
                history.push('/workshop')
              })
              .catch(({ response }) => {
                console.log(`response2`, response)
                const {
                  status,
                  data: { errorMessage }
                } = response
                if (status === 422) {
                  const message = errorMessage.split(':')
                  const splitMessage = _.last(message).split('.')
                  this.setState({
                    isDisable: true,
                    isLoading: false,
                    isPublish: false,
                    errorData: {
                      title: splitMessage[0],
                      subTitle: splitMessage[1]
                    },
                    isOverLimit: true
                  })
                }
                if (status === 400) {
                  // const message = errorMessage.split(':')
                  // const splitMessage = _.last(message).split('.')
                  message.error(errorMessage)
                  this.setState({
                    isDisable: true,
                    isLoading: false,
                    isPublish: false
                    // isOverLimit: true
                  })
                }
              })
          }
        )
      }
    })
  }

  checkCoachingScheduling = async () => {
    const {
      current,
      eventTime,
      eventDuration,
      isMarketPlaceEnabled,
      selectedType
    } = this.state
    const normalCoaching =
      current.host_emails.length > 0 && eventTime && eventDuration
    const coachingForMarketplace =
      isMarketPlaceEnabled && selectedType.includes(4)

    if (normalCoaching || coachingForMarketplace) {
      this.setState({ isDisable: false })
    } else {
      await this.checkTimeError()
    }
  }

  renderError = (t, errorData) => {
    return (
      <div>
        <div>{errorData.title}.</div>
        {errorData.subTitle && <div>{errorData.subTitle}.</div>}
        <Trans i18nKey="labels:contact_admin">
          <span style={{ justifyContent: 'center' }}>
            If you wish to change your monthly live-streaming limit please
            contact
          </span>
          <a
            style={{ justifyContent: 'center' }}
            href="mailto:admin@sharelook.com"
          >
            admin@sharelook.com
          </a>
        </Trans>
      </div>
    )
  }
  onAdminOnly = () => {
    this.setState(state => ({
      isAdminOnly: !state.isAdminOnly
    }))
  }

  handleSelectedOrganizations = id => {
    const { selectedOrganizations } = this.state

    if (selectedOrganizations.includes(id)) {
      this.setState({
        selectedOrganizations: selectedOrganizations.filter(
          organizationId => organizationId !== id
        )
      })
    } else {
      this.setState({
        selectedOrganizations: [...selectedOrganizations, id]
      })
    }
  }

  handleSelectedGroups = (groupId, orgId) => {
    const { selectedGroups } = this.state
    this.setState({
      selectedGroups: selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId]),
      selectedOrganizations: [orgId]
    })
  }

  handleSelectedAllOrganizations = id => {
    const { list } = this.props
    const { selectedGroups } = this.state

    const { groups = [] } = list.find(i => i.id === id)
    const groupIds = groups.map(i => i.id)

    const selectedOrgGroups = groupIds.filter(i => selectedGroups.includes(i))

    if (groupIds.length === selectedOrgGroups.length) {
      this.setState({
        selectedGroups: selectedGroups.filter(
          i => !selectedOrgGroups.includes(i)
        ),
        selectedOrganizations: [id]
      })
    } else {
      this.setState({
        selectedGroups: selectedGroups
          .filter(i => !groupIds.includes(i))
          .concat(groupIds),
        selectedOrganizations: [id]
      })
    }
  }

  handleChangeTerms = event => {
    const {
      isPublish,
      eventTime,
      eventDuration,
      encoderType,
      current
    } = this.state
    const checkedValue = event.target.checked
    const eventId = current.id
    const isCoaching = encoderType === 'peer2peer'

    const eventPublishCondition =
      eventId && checkedValue && eventTime && eventDuration
    const coachingPublishCondition = isCoaching && eventId && checkedValue

    if (!isPublish) {
      this.setState(
        {
          checkedTerms: checkedValue,
          isDisable:
            eventPublishCondition || coachingPublishCondition ? false : true
        },
        () => {
          if (!isCoaching && (!eventTime || !eventDuration)) {
            this.checkTimeError()
          }
        }
      )
    }
  }

  checkTimeError = () => {
    const { eventTime, eventDuration } = this.state
    if (this.checkFinish(eventTime, eventDuration)) {
      if (!eventTime) {
        message.error(i18n.t('errors:start_date'))
        return
      }

      if (!eventDuration) {
        message.error(i18n.t('errors:duration'))
        return
      }
    }
  }

  handleSwitch = val => {
    this.setState(
      {
        is_free: val
      },
      () => this.handleForm()
    )
  }

  onSendEmail = value => {
    this.setState({ isSendMail: value })
  }

  onEnableAI = value => {
    this.setState({ is_ai_enabled: value })
  }

  handleRewardImageUpload = (type, id) => {
    this.setState(
      { [`${type === 'Certificate' ? 'cert_id' : 'badge_id'}`]: id },
      () => this.handleForm()
    )
  }

  handleNonMarketplaceFields = val =>
    this.setState({ shouldHideNonMarketplaceFields: val })

  render() {
    const { t } = this.props
    const {
      tag,
      isEventMarketPlace,
      hasZoomIntegrated,
      checkingZoomStatus
    } = this.state
    const { id } = this.props.match.params
    const isEdit = Boolean(id)

    const isZoomMeeting = tag.value === 'zoom_meeting'
    const eventType = tag.value
    const viewForm = isZoomMeeting
      ? !checkingZoomStatus && hasZoomIntegrated
      : !isEventMarketPlace

    const tagsData = [
      { value: 'conference', text: t('v3:private_conference') },
      { value: 'webinar', text: t('v3:webinar') },
      { value: 'agora_conference', text: t('v3:public_conference') },
      { value: 'peer2peer', text: t('v4:coaching_session') },
      { value: 'live_assessment', text: t('v2:live_assessment') }
    ]

    return (
      <Fragment>
        <Header
          {...this.props}
          {...this.state}
          eventId={id}
          isEventMarketPlace={isEventMarketPlace}
          onToggleMarketPlace={this.handleToggleMarketPlace}
          eventType={eventType}
          // eventMarketPlaceOption={eventMarketPlaceOption}
        />
        <EventContainer>
          {tagsData.map(each => (
            <Tag
              key={each.value}
              text={each.text}
              value={each.value}
              isEdit={isEdit}
              onClick={() => this.handleTags(each)}
              active={tag.value === each.value}
              disabled={isEdit}
            />
          ))}
          {viewForm && (
            <CreateForm {...this.props} {...this.state} App={this} />
          )}
          {/* {!isEventMarketPlace && tag.value === 'public' &&
            <PublicForm 
            />
          } */}

          {checkingZoomStatus &&
            message.loading('Checking your zoom integration status...')}

          {!isEventMarketPlace &&
            isZoomMeeting &&
            !hasZoomIntegrated &&
            !checkingZoomStatus && <ZoomForm isLoading={checkingZoomStatus} />}
          {isEventMarketPlace && (
            <MarketPlaceForm {...this.props} {...this.state} App={this} />
          )}
        </EventContainer>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('events')(Create))
