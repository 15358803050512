import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { MessageContainer } from './styled'
import moment from 'moment'
import { config } from '../../../constants'
import DiscussionForm from '../Form'
import { Input } from 'antd'
import ReactSVG from 'react-svg'
import More from '../../../views/Events/Preview/assets/more.svg'
import Edit from '../../../views/Events/Preview/assets/edit.svg'
import Delete from '../../../views/Events/Preview/assets/delete.svg'
import classnames from 'classnames'

class Message extends Component {
  state = {
    reply: false,
    showMoreOptions: false,
    editing: false,
    editedMessage: '',
    error: ''
  }

  componentDidMount() {
    this.setState({
      editedMessage: this.props.message.body
    })
  }

  reply = () => {
    if (this.props.isRoot) {
      this.props.replyTo(this.props.message.id)
    } else {
      this.props.replyTo(this.props.parentId)
    }
  }

  toggleShowMore = message => {
    if (this.state.editing) {
      this.setState({
        editing: !this.state.editing
      })
    }
    this.setState({
      showMoreOptions: !this.state.showMoreOptions
    })
    if (message) {
      this.props.onClickTabItem(message.id)
    }
  }

  handleEditMessage = message => {
    this.setState(
      {
        editing: !this.state.edit,
        activeId: message.id
      },
      this.toggleShowMore()
    )
  }

  handleDeleteMessage = async message => {
    await this.props.deleteComment(encodeURIComponent(message.id))
    this.toggleShowMore(message)
  }

  handleMessageChange = event => {
    this.setState({
      editedMessage: event.target.value
    })
  }

  handleKeyPress = async event => {
    this.props.stopEditingMessage()
    if (event.key === 'Enter' && this.state.editedMessage.length > 0) {
      try {
        await this.props.editComment({
          commentId: encodeURIComponent(this.props.message.id),
          content: this.state.editedMessage
        })
      } catch (error) {
        this.setState({
          error
        })
      } finally {
        this.setState({
          editing: false
        })
      }
    }
  }

  urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.split(urlRegex).map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <a target="_blank" rel="noopener noreferrer" href={part} key={i}>
            {part}
          </a>
        )
      }
      return part
    })
  }

  renderMessage = message => {
    if (message.body) {
      return message.body.split('\n').map((item, key) => {
        return <p key={key}>{this.urlify(item)}</p>
      })
    }
    return null
  }

  renderEditMessage = () => {
    return (
      <Input.TextArea
        name="comment"
        value={this.state.editedMessage}
        onChange={this.handleMessageChange}
        onKeyPress={this.handleKeyPress}
      />
    )
  }

  cancelEdit = () => {
    this.setState({
      editing: false
    })
  }

  render() {
    if (!this.props.t) {
      return null
    }

    const {
      message,
      currentUser,
      isLoading,
      stopEditingMessage,
      editingMessage,
      activeTab,
      onClickTabItem,
      discussionType,
      discussionId,
      t,
      discussion
    } = this.props

    const { editing, showMoreOptions } = this.state
    const replyActive = discussion && discussion.activeReplyId === message.id

    return (
      <MessageContainer>
        <MessageContainer.Avatar>
          <img
            alt=""
            src={`${config.baseURL}users/${message.user_id}/avatar`}
            width="50px"
            height="50px"
          />
        </MessageContainer.Avatar>
        <MessageContainer.Main>
          <MessageContainer.Main.Header>
            <MessageContainer.Main.Header.Name>
              {message.username}
            </MessageContainer.Main.Header.Name>
            <MessageContainer.Main.Header.Date>
              {moment(message.timestamp).format('lll')}
            </MessageContainer.Main.Header.Date>
            <MessageContainer.Main.Header.More>
              {Number(message.user_id) === currentUser && (
                <ReactSVG
                  src={More}
                  className={classnames('message-more-options', {
                    'message-more-options-active': showMoreOptions
                  })}
                  onClick={() => this.toggleShowMore(message)}
                />
              )}
              {!editing && !isLoading && showMoreOptions && (
                <MessageContainer.Main.Header.MoreOptions>
                  <div
                    className="more-options-item"
                    onClick={() => this.handleEditMessage(message)}
                  >
                    <img alt="" src={Edit} />
                    <span>{t('v3:message_edit')}</span>
                  </div>
                  <div
                    className="more-options-item"
                    onClick={() => this.handleDeleteMessage(message)}
                  >
                    <img alt="" src={Delete} />
                    <span>{t('v3:message_delete')}</span>
                  </div>
                </MessageContainer.Main.Header.MoreOptions>
              )}
            </MessageContainer.Main.Header.More>
          </MessageContainer.Main.Header>
          <MessageContainer.Main.Message>
            {editing && !isLoading
              ? this.renderEditMessage()
              : this.renderMessage(message)}
          </MessageContainer.Main.Message>
          {!replyActive && !editing && (
            <MessageContainer.Main.Footer>
              <MessageContainer.Main.Footer.Link onClick={this.reply}>
                {t('v3:message_reply')}
              </MessageContainer.Main.Footer.Link>
            </MessageContainer.Main.Footer>
          )}
          {!replyActive && editing && (
            <MessageContainer.Main.Footer>
              <MessageContainer.Main.Footer.Link onClick={this.cancelEdit}>
                {t('buttons:cancel')}
              </MessageContainer.Main.Footer.Link>
            </MessageContainer.Main.Footer>
          )}
          {message.replies > 0 &&
            message.reply.map((item, key) => (
              <Message
                key={key}
                currentUser={currentUser}
                discussionId={discussionId}
                discussionType={discussionType}
                activeTab={activeTab}
                message={item}
                isRoot={false}
                parentId={message.id}
                replyTo={this.props.replyTo}
                deleteComment={this.props.deleteComment}
                editComment={this.props.editComment}
                getByEventId={this.props.getByEventId}
                stopEditingMessage={stopEditingMessage}
                editingMessage={editingMessage}
                onClickTabItem={onClickTabItem}
                t={t}
              />
            ))}
          {replyActive && (
            <DiscussionForm
              discussionId={discussionId}
              discussionType={discussionType}
              parentId={message.id}
            />
          )}
        </MessageContainer.Main>
      </MessageContainer>
    )
  }
}

export default withTranslation()(Message)
