import React, { useMemo } from 'react'
import history from '../../../../history'

/**
Creates buttons based on user permissions and organization settings.
@param {object} props - Props object containing t, user info, and organization info.
@returns {JSX.Element} - Returns a JSX element containing WorkshopCreateButton components. */
function CreateButtons(props) {
  const {
    t,
    user: {
      info: { organization, isSuperAdmin, isAdmin }
    }
  } = props

  const shouldShowMazeCreateButton = useMemo(() => {
    const hasAdminAccess = isSuperAdmin || isAdmin
    return organization && organization.enable_maze && hasAdminAccess
  }, [organization, isSuperAdmin, isAdmin])

  return (
    <>
      <WorkshopCreateButton
        onClickPath="/course-create"
        title={t('workshop:new_course')}
      />
      <WorkshopCreateButton
        onClickPath="/video-create"
        title={t('v4:generate_video_text')}
      />
      <WorkshopCreateButton
        onClickPath="/scorm-create"
        title={t('v4:upload_scorm_course')}
      />
      <WorkshopCreateButton onClickPath="/events" title={t('new_event')} />
      {shouldShowMazeCreateButton && (
        <WorkshopCreateButton
          onClickPath="/maze/create"
          title={t('v4:new_maze')}
        />
      )}
      <WorkshopCreateButton
        onClickPath="/skill-journey"
        title={t('v4:new_skill_journey')}
      />
      <WorkshopCreateButton
        onClickPath="/channel"
        title={t('v3:create_a_channel')}
      />
      {(isSuperAdmin || isAdmin) && (
        <WorkshopCreateButton
          onClickPath="/share-sphere/list"
          title={t('v4:shareSphere_game')}
        />
      )}
      <WorkshopCreateButton
        onClickPath="/podcast-create"
        title={t('v4:new_discussion_room')}
      />
    </>
  )
}

function WorkshopCreateButton({ onClickPath, title }) {
  return (
    <div className="button-plus" onClick={() => history.push(onClickPath)}>
      <div className="button-plus__icon" />
      <div className="button-plus__text">{title}</div>
    </div>
  )
}

export default CreateButtons
