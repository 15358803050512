import React, { Component, Fragment } from 'react'
import { Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

class DiscussionForm extends Component {
  onPressEnter = e => {
    if (e.shiftKey === false) {
      const value = this.props.form.getFieldValue('comment')
      if (value !== '') {
        this.props.postComment(
          this.props.discussionId,
          this.props.discussionType,
          this.props.parentId,
          value
        )
      }
      this.props.form.setFieldsValue({ comment: '' })
      e.preventDefault()
    }
  }

  getSmallProfileImage = image => {
    if (image) {
      const { link, resizes } = image
      if (_.isEmpty(resizes)) {
        return link
      } else {
        return resizes.thumbnail
      }
    }
    return ''
  }

  render() {
    const {
      user,
      form: { getFieldDecorator },
      t,
      posting,
      isDisabled
    } = this.props
    return (
      <Fragment>
        <Form>
          <Form.Item>
            {getFieldDecorator('comment')(
              <Input.TextArea
                name="comment"
                placeholder={t('discussion:join_discussion')}
                style={{
                  paddingLeft: '60px',
                  backgroundImage: `url(${this.getSmallProfileImage(
                    user.info.avatar
                  )})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px 10px',
                  backgroundSize: '40px auto'
                }}
                disabled={posting || isDisabled}
                onPressEnter={this.onPressEnter}
                autoSize={{ minRows: 3 }}
              />
            )}
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation()(DiscussionForm))
