import React from 'react'
import ReactEcharts from 'echarts-for-react'
import './style.scss'
import { withTranslation } from 'react-i18next'

class OverviewMarketPlaceChart extends React.Component {
  render() {
    const {
      graphData: {
        course = 0,
        webinar = 0,
        coaching = 0,
        maze = 0,
        channels = 0,
        publicConference = 0
      },
      enableMaze,
      t
    } = this.props

    const total =
      course + webinar + coaching + maze + publicConference + channels

    const coursePercentage = ((course / total) * 100).toFixed(0)
    const webinarPercentage = ((webinar / total) * 100).toFixed(0)
    const CoachingPercentage = ((coaching / total) * 100).toFixed(0)
    const mazePercentage = ((maze / total) * 100).toFixed(0)
    const channelsPercentage = ((channels / total) * 100).toFixed(0)
    const publicConferencePercentage = (
      (publicConference / total) *
      100
    ).toFixed(0)

    var colors = [
      '#FFB37C',
      '#A4EFA7',
      '#8DC3F8',
      '#246A9C',
      '#B95EF0',
      '#E1D917',
      '#246A9C'
    ]
    return (
      <div className="overview-chart">
        <div className="overview-chart-diagram">
          <ReactEcharts
            option={{
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {d}%'
              },
              legend: {
                // orient: 'vertical',
                // x: 'left',
                icon: 'square',
                data: [
                  t('general:course'),
                  t('v3:webinar'),
                  t('v3:coaching'),
                  t('v3:channels'),
                  enableMaze && t('v4:maze'),
                  t('v3:public_conference')
                ]
              },
              toolbox: {
                show: true,
                feature: {
                  mark: { show: true },
                  magicType: {
                    show: true,
                    type: ['pie', 'funnel'],
                    option: {
                      funnel: {
                        x: '25%',
                        width: '50%',
                        funnelAlign: 'center',
                        max: 1548
                      }
                    }
                  }
                  // saveAsImage: { show: true, title: 'Download chart...' }
                }
              },
              series: [
                {
                  name: 'Overview Average Activity',
                  type: 'pie',
                  radius: ['35%', '70%'],
                  // right: '-25%',
                  top: '10%',
                  label: {
                    show: true,
                    position: 'inner',
                    formatter: function(params) {
                      return params && params.value > 0
                        ? params.value + '%\n'
                        : ''
                    }
                  },
                  data: [
                    { value: coursePercentage, name: t('general:course') },
                    { value: webinarPercentage, name: t('v3:webinar') },
                    { value: CoachingPercentage, name: t('v3:coaching') },
                    {
                      value: channelsPercentage,
                      name: t('v3:channels')
                    },
                    enableMaze && { value: mazePercentage, name: t('v4:maze') },
                    {
                      value: publicConferencePercentage,
                      name: t('v3:public_conference')
                    }
                  ],
                  color: colors
                }
              ]
            }}
          />
        </div>
        <div className="overview-chart-notes">{t('v3:compare_last_month')}</div>
      </div>
    )
  }
}

export default withTranslation()(OverviewMarketPlaceChart)
