import React, { useState, useRef, useEffect } from 'react'
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  message,
  Icon,
  Spin,
  Alert,
  Result,
  Modal
} from 'antd'
import axios from 'axios'
import VoiceSettings from './VoiceSettings'
import './Voiceover.scss'

const apiKey = 'sk_f63ba915b2d0500626e605b09990e56938aa0653fada90a7'
const { Search } = Input

const VoiceoverGenerator = () => {
  const [text, setText] = useState(
    'This is the text generated from the previous step. You can edit it.'
  )
  const [voices, setVoices] = useState([])
  const [loadingVoices, setLoadingVoices] = useState(false)
  const [selectedVoice, setSelectedVoice] = useState(null)
  const [currentAudio, setCurrentAudio] = useState(null)
  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [playingVoiceId, setPlayingVoiceId] = useState(null)
  const [settings, setSettings] = useState({
    model: 'eleven_multilingual_v2',
    stability: 0.5,
    similarity_boost: 0.75,
    style: 0.5,
    use_speaker_boost: true
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [generatedAudio, setGeneratedAudio] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const audioRef = useRef(null)

  useEffect(() => {
    fetchVoices()
  }, [])

  const fetchVoices = async () => {
    try {
      setLoadingVoices(true)
      const response = await axios.get('https://api.elevenlabs.io/v1/voices', {
        headers: {
          Accept: 'application/json',
          'xi-api-key': apiKey
        }
      })
      setVoices(
        response.data.voices.filter(voice => voice.category === 'premade')
      )
    } catch (error) {
      message.error('Failed to fetch voices')
    } finally {
      setLoadingVoices(false)
    }
  }

  const handlePlaySample = (voiceId, previewUrl, e) => {
    e.stopPropagation()
    if (currentAudio) {
      currentAudio.pause()
      currentAudio.currentTime = 0
      setPlayingVoiceId(null)
    }

    const audio = new Audio(previewUrl)
    setCurrentAudio(audio)
    setPlayingVoiceId(voiceId)

    audio.play()
    audio.onended = () => {
      setCurrentAudio(null)
      setPlayingVoiceId(null)
    }
  }

  const handleGenerateVoiceover = async () => {
    if (!selectedVoice) {
      message.warning({
        content: 'Please select a voice first',
        icon: <Icon type="info-circle" />
      })
      return
    }

    if (!text.trim()) {
      message.warning({
        content: 'Please enter some text for the voiceover',
        icon: <Icon type="info-circle" />
      })
      return
    }

    setIsGenerating(true)

    try {
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice.voice_id}`,
        {
          text,
          voice_settings: settings
        },
        {
          headers: {
            Accept: 'audio/mpeg',
            'xi-api-key': apiKey,
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      )

      const url = URL.createObjectURL(response.data)
      setGeneratedAudio(url)
      if (audioRef.current) {
        audioRef.current.src = url
      }
      message.success({
        content: 'Voiceover generated successfully',
        icon: <Icon type="check-circle" />
      })
    } catch (error) {
      message.error({
        content: 'Failed to generate voiceover',
        icon: <Icon type="close-circle" />
      })
    } finally {
      setIsGenerating(false)
    }
  }

  const handleSettingsChange = (key, value) => {
    if (key === 'reset') {
      setSettings({
        model: 'eleven_multilingual_v2',
        stability: 0.5,
        similarity_boost: 0.75,
        style: 0.5,
        use_speaker_boost: true
      })
    } else if (key === 'apply') {
      setIsSettingsVisible(false)
    } else {
      setSettings(prev => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleCreateNew = () => {
    Modal.confirm({
      centered: true,
      title: 'Do you want to create a new voiceover?',
      content: 'This will overwrite the current voiceover.',
      onOk: () => {
        setGeneratedAudio(null)
      },
      onCancel: () => {
        console.log('Cancel')
      }
    })
  }

  const renderVoiceLabels = labels => {
    if (!labels) return null

    return (
      <div className="voice-labels">
        {Object.entries(labels).map(([key, value]) => (
          <span key={key} className="voice-label">
            <Icon type={getLabelIcon(key)} />
            {`${key}: ${value}`}
          </span>
        ))}
      </div>
    )
  }

  const getLabelIcon = labelType => {
    const iconMap = {
      accent: 'global',
      description: 'info-circle',
      age: 'user',
      gender: 'team',
      use_case: 'tag'
    }
    return iconMap[labelType] || 'tag'
  }

  const filteredVoices = voices.filter(voice => {
    // Check if voice name includes search query
    const matchesName = voice.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())

    // Check if any label value includes search query
    const matchesLabels = Object.values(voice.labels || {}).some(value =>
      value.toLowerCase().includes(searchQuery.toLowerCase())
    )

    return matchesName || matchesLabels // Return true if either condition is met
  })

  return (
    <div className="voiceover-generator">
      <div className="header">
        <h1>Generate Voiceover</h1>
        <p>Select voice settings and generate a voiceover.</p>
      </div>

      <Input.TextArea
        value={text}
        onChange={e => setText(e.target.value)}
        rows={4}
        className="text-input"
        placeholder="Enter text for voiceover generation..."
      />

      {!generatedAudio && (
        <>
          <div className="search-section">
            <Row gutter={16} align="middle">
              <Col flex="auto" span={16}>
                <Search
                  prefix={<Icon type="search" />}
                  placeholder="Search voices by name or label..."
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </Col>
              {selectedVoice && (
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={() => setIsSettingsVisible(true)}
                  >
                    <Icon type="setting" /> Voice Settings
                  </Button>
                </Col>
              )}
            </Row>
          </div>

          {!selectedVoice && (
            <div className="selection-hint">
              <Icon type="info-circle" /> Click on a voice to select and
              generate voiceover
            </div>
          )}

          <div className="voice-list">
            {loadingVoices && (
              <div>
                {' '}
                <Spin size="small" /> {'  '}Loading voices...
              </div>
            )}
            {filteredVoices.map(voice => (
              <Card
                hoverable
                className={`voice-card ${
                  selectedVoice && selectedVoice.voice_id === voice.voice_id
                    ? 'selected'
                    : ''
                }`}
                onClick={() => setSelectedVoice(voice)}
              >
                <div className="voice-card-content">
                  <div className="voice-info">
                    <h3>{voice.name}</h3>
                    <Button
                      type="primary"
                      onClick={e =>
                        handlePlaySample(voice.voice_id, voice.preview_url, e)
                      }
                    >
                      <Icon
                        type={
                          playingVoiceId === voice.voice_id
                            ? 'pause-circle'
                            : 'play-circle'
                        }
                      />
                      {playingVoiceId === voice.voice_id
                        ? 'Playing'
                        : 'Play Sample'}
                    </Button>
                  </div>
                  {renderVoiceLabels(voice.labels)}
                </div>
              </Card>
            ))}
          </div>

          <div className="generate-section">
            <Button
              type="primary"
              size="large"
              style={{ padding: 'default 30px' }}
              onClick={handleGenerateVoiceover}
              disabled={!selectedVoice || isGenerating}
            >
              <Icon type={isGenerating ? 'loading' : 'robot'} />
              {isGenerating ? 'Generating Voiceover...' : 'Generate Voiceover'}
            </Button>
          </div>
        </>
      )}

      {generatedAudio && (
        <Card>
          <Result
            status="success"
            title="Successfully generated voiceover audio."
            subTitle="You can preview or download the generated voiceover."
            extra={[
              <Button type="primary" key="preview">
                Use as Voiceover
              </Button>,
              <Button key="use" type="primary" ghost onClick={handleCreateNew}>
                Create New
              </Button>
            ]}
          />

          <div className="audio-player">
            <audio controls>
              <source src={generatedAudio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </Card>
      )}

      <VoiceSettings
        visible={isSettingsVisible}
        onClose={() => setIsSettingsVisible(false)}
        settings={settings}
        onSettingsChange={handleSettingsChange}
      />
    </div>
  )
}

export default VoiceoverGenerator
